<h2 mat-dialog-title>Novi avansni račun</h2>
<mat-dialog-content class="mat-typography">
    <div class="payments-row">
        <div>
            <h4 class="payments-title">Način plaćanja</h4>
            <div class="payment-options">
                <div>
                    <mat-checkbox *ngFor="let option of paymentTypeOptions"
                    [(ngModel)]="selectedOptions[option.value]['checked']"
                    (change)="paymentOptionChanged(option.value)">{{option.name}}</mat-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div [formGroup]="paymentAmounts" class="payment-amounts-form">
        <ng-container *ngFor="let item of paymentTypeOptions; let j = index">
            <mat-form-field *ngIf="selectedOptions[item.value]?.checked">
                <input matInput [placeholder]="selectedOptions[item.value]['name']" [formControlName]="item.value"
                    [value]="0">
            </mat-form-field>
        </ng-container>
    </div>
    <ng-container >
        <h4>Lista usluga</h4>
        <div class="row-inline" *ngFor="let formGroup of fiscalForms.controls; let i = index">
            <dynamic-form [class]="'fiscal fiscal-' + i" [form]="getFiscalForm(i)"
                [config]="fiscalReceiptsConfig[i]"></dynamic-form>
            <button [disabled] ="changingItemsDisabled" mat-button class="delete-btn" (click)="deleteFiscal(i)">
                <mat-icon mat-list-icon>delete</mat-icon>
                <span>Obriši</span>
            </button>
        </div>
        <button [disabled]="changingItemsDisabled" mat-stroked-button color="primary" (click)="addFiscal()">
            <mat-icon>add</mat-icon> Dodaj uslugu / proizvod
        </button>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Odustani</button>
    <button mat-raised-button color="primary" (click)="closeDialog()">Fiskalizuj</button>
</mat-dialog-actions>