import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {prepareFormGroup} from '../../shared/helpers/form';
import { FieldConfig } from 'src/app/components/dynamic-form-master/models/field-config.interface';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';
import { TransactionService } from 'src/app/transaction-new/transaction.service';
import { FiscalInvoiceType } from './fiscal-invoice-type';
import { FiscalReceiptService } from './fiscal-receipt-service';
import { FormEventService } from 'src/app/components/dynamic-form-master/form-event.service';

export interface FiscalRequest {
  doFinal: 1 | 0;
  contractId: number; //contract id
  invoiceType?: number;
  invoiceDate?: string | null;
  transactionType: number;
  payment: Array<{amount: number, paymentType: number}>;
  referentDocumentNumber: string | null;
  items: Array<{name: string, quantity: number, unitPrice: number, totalAmount: number, labels: Array<string>}>;
}

@Component({
  selector: 'app-fiscal-advance-generate',
  styleUrls: ['./fiscal-advance-generate.component.scss'],
  templateUrl: './fiscal-advance-generate.component.html'
})
export class FiscalAdvanceGenerateComponent implements OnInit {
  fiscalReceiptsConfig = [];
  fiscalForms = new UntypedFormArray([]);
  paymentTypes = new UntypedFormGroup({
    type: new UntypedFormControl([1]),
  }); //kes
  paymentAmounts = new UntypedFormGroup({})

  paymentTypeOptions = [
    {value: 1, name: 'Gotovina'},
    {value: 2, name: 'Kartica'},
    {value: 3, name: 'Ček'},
    {value: 4, name: 'Virman'},
    {value: 5, name: 'Vaučer'},
    {value: 0, name: 'Ostalo'},
  ];
  paymentOptionsMappings = [];

  selectedOptions = {};
  datepickerConfig = {
    type: 'datepicker',
    label: 'Datum',
    name: 'fiscal_date',
    placeholder: 'Datum',
    validation: [Validators.required],
    col: 2
  };
  datepickerGroup: UntypedFormGroup = new UntypedFormGroup({
    fiscal_date: new UntypedFormControl()
  });
  changingItemsDisabled = false;
  totalFiscalProductsValue = 0;
  totalPayedIn = 0;
  isAdvancedPayment = true;
  hasReferentFiscal = false;
  doFinalFiscalEnabled = false;

  allSub = [];
  readonly MAIN_CURRENCY_NAME = TransactionService.MAIN_CURRENCY.name;
  constructor(
    private matDialogRef: MatDialogRef<FiscalAdvanceGenerateComponent>,
    private fiscalService: FiscalReceiptService,
    private respMsgService: ResponseMessageService,
    @Inject(MAT_DIALOG_DATA) public data: {
      name: string;
      quantity: string;
      unitPrice: number;
      advanceAmount: number;
      transactionType: number;
      referentAdvanceFiscal: string | null
      paymentType: Array<{rawData: {type: string, amount: number}}>;
      fiscalItems: Array<any> | null;
      totalPayedInAdvance?: string;
      amountOnFirstAdvanceFiscal: string;
      referenceItemId: number;
    },
    private fb: UntypedFormBuilder,
    private respMsg: ResponseMessageService,
    private transactionService: TransactionService,
    private formEventService: FormEventService,
  ) {
    console.log(this.data);
  }

  ngOnInit(): void {
    this.hasReferentFiscal = this.data.referentAdvanceFiscal ? true : false;
    this.totalPayedIn = this.isAdvancedPayment ? parseFloat(this.data.totalPayedInAdvance) : 0;
    this.transactionService.getAccountTypes().subscribe(account => this.paymentOptionsMappings = account);
    this.paymentTypeOptions.forEach(option => {
      const moneyType = option.value;
      let checked = false;
      this.selectedOptions[moneyType] = {
        checked:  checked,
        name: option.name
      };
    });

    if (this.data.fiscalItems) {
      this.changingItemsDisabled = this.isAdvancedPayment && this.hasReferentFiscal ? true : false; //@todo also check does it have reference
      let totalItemsValue = 0;
      this.data.fiscalItems.forEach(fiscalItem => {
        totalItemsValue += parseFloat(fiscalItem.totalAmount);
        this.setFiscalReceiptConfig(fiscalItem, this.changingItemsDisabled);
      });
      this.totalFiscalProductsValue += totalItemsValue;
      this.doFinalFiscalEnabled = this.doFinalFiscalPossible();
    } else {
      this.setFiscalReceiptConfig(this.data);
    }

    this.allSub.push(this.formEventService.eventEmitter$.subscribe(event => {
      if (event.message == 'lt-advance-fiscal-payment-changed') {
        this.doFinalFiscalEnabled = this.doFinalFiscalPossible();
      }
    }));
  }

  paymentAmountKeys() {
    return Object.keys(this.paymentAmounts.controls);
  }
  
  paymentOptionChanged(paymentType) {
    const isSelected = this.selectedOptions[paymentType]['checked'];
    if (isSelected) {
        this.paymentAmounts.addControl(paymentType, this.fb.control(0));
    } else {
      delete this.paymentAmounts.controls[paymentType];
    }
  }

  setPaymentTypeAmounts() {
    const paymentAmounts = this.paymentAmounts as UntypedFormGroup;
    Object.keys(this.paymentTypeOptions).forEach(key => {
      const type = this.paymentTypeOptions[key].value;
      if (this.selectedOptions[type]['checked'] == true) {
        const moneyTypesInternal = this.paymentOptionsMappings.filter(el => el.fiscalId == type);
        let amount = 0;
        moneyTypesInternal.forEach(moneyTypeInternal => {
          const info = this.data.paymentType.find( el => parseInt(el.rawData.type) == parseInt(moneyTypeInternal.id));
          amount += info && info.rawData.amount ? info.rawData.amount : 0;
        });
        paymentAmounts.addControl(type, this.fb.control(amount));
      }
    });
  }

  setFiscalReceiptConfig(values: {
    name: string,
    quantity: string,
    unitPrice: number,
    advanceAmount: number,
    labels?: Array<string> | null,
    
  } | null = null, disabled: boolean = false) {
    
    const config: FieldConfig[] = [
      {
        type: 'input',
        label: 'Opis usluge',
        name: 'name',
        placeholder: 'Opis usluge',
        validation: [Validators.required],
        value: values?.name || '',
        disabled: disabled,
        col:4
      },
      {
        type: 'input',
        label: 'Količina',
        name: 'quantity',
        placeholder: 'Količina',
        validation: [Validators.required],
        value: values?.quantity || 1,
        disabled: disabled,
        col:1
      },
      {
        type: 'input',
        label: 'Cena',
        name: 'unitPrice',
        placeholder: 'Cena',
        validation: [Validators.required],
        value: values?.unitPrice || '',
        disabled: disabled,
        col:2
      },
      {
        type: 'select',
        label: 'PDV kategorija',
        name: 'labels',
        multiple: true,
        validation: [Validators.required],
        options: [
          { id: 'А' , name: 'А'},
          { id: 'F' , name: 'F'}
        ],
        placeholder: 'PDV kategorija',
        value: values?.labels || null,
        disabled: disabled,
        col:this.isAdvancedPayment ? 3 : 4
      },
      {
        type: this.isAdvancedPayment ? 'input' : 'hidden',
        label: 'Iznos avansa',
        name: 'advanceAmount',
        placeholder: 'Iznos avansa',
        //validation: [Validators.required],
        value: 0,
        //disabled: disabled,
        col:2,
        eventMessages: {
          onKeyup : 'lt-advance-fiscal-payment-changed'
        }
      },
    ];

    this.fiscalReceiptsConfig.push(config);
    this.fiscalForms.push(prepareFormGroup(config));
  }
  
  getFiscalForm(index) {
    return this.fiscalForms.at(index) as UntypedFormGroup;
  }
  addFiscal() {
    this.setFiscalReceiptConfig();
  }
  deleteFiscal(index) {
    this.fiscalForms.removeAt(index);
    this.fiscalReceiptsConfig.splice(index,1);
  }

  createAdvanceFiscal(doFinal = false) {
    let invalid = false;
    let items  = [];
    let totalServices = 0;
    let totalAdvanceAmount = 0;
    for (let form of this.fiscalForms.controls) {
      if (form.invalid) {
        invalid = true;
        form.markAllAsTouched();
      }
      let properties = form.getRawValue();
      properties.totalAmount = properties.quantity * properties.unitPrice;
      totalServices += properties.totalAmount;
      totalAdvanceAmount += parseFloat(properties.advanceAmount);
      items.push(properties);
    }
    if (invalid) {
      this.respMsg.showError('Ispunite / selektujte sva obavezna polja');
      return;
    }
    const amountsPerMoneyType =this.paymentAmounts.getRawValue();
    const payments = [];
    let totalPayments = 0;
    Object.keys(amountsPerMoneyType).forEach(type => {
      const amount = parseFloat(amountsPerMoneyType[type]);
      payments.push({
        amount: amount,
        paymentType: type  
      });
      totalPayments+= amount;
    });
    //only for normal
    const invoiceDate = this.datepickerGroup.controls['fiscal_date'].value;
    if (!invoiceDate) {
      this.respMsg.showError('Odaberite datum fiskala');
      return;
    }
    if (totalAdvanceAmount != totalPayments) {
      this.respMsg.showError('Ukupna uplata se mora poklapati sa ukupnim avansom unetim kod proizvoda/usluga. Trenutno je Uplata: ' + totalPayments + ' Avans total: ' + totalAdvanceAmount);
      return;
    }

    let fiscalData: FiscalRequest = {
      contractId: this.data.referenceItemId,
      invoiceType: FiscalInvoiceType.ADVANCED,
      transactionType:0,
      payment: payments,
      items,
      invoiceDate: invoiceDate,
      referentDocumentNumber: this.data?.referentAdvanceFiscal ?? null,
      doFinal: doFinal === true ? 1 : 0
    };

    this.fiscalService.generateAdvanceFiscal(fiscalData).subscribe(
      resp => {
          let responseData = resp?.data;
          if (resp.success && responseData) {
              this.respMsgService.showSuccess( ( doFinal ? 'Finalni ' : 'Avansni') + ' fiskal uspešno kreiran');
              this.matDialogRef.close({
                success: true,
                data: responseData,
                isFinal: doFinal
              });
          }
      }, err => {
        this.respMsgService.showError(err?.error?.message);
      }
  ).add(() => {
      //this.sendingToCis = false;
  });
  }

  paymentChanged() {
    this.formEventService.eventEmitter$.next({
      message: 'lt-advance-fiscal-payment-changed'
    });
  }

  doFinalFiscalPossible() {
    const amountsPerMoneyType = this.paymentAmounts.getRawValue();
    let totalPayments = 0;
    Object.keys(amountsPerMoneyType).forEach(type => {
      const amount = parseFloat(amountsPerMoneyType[type]);
      totalPayments += amount;
    });

    let totalAdvanceAmount = 0;
    for (let form of this.fiscalForms.controls) {
      let properties = form.getRawValue();
      totalAdvanceAmount += parseFloat(properties.advanceAmount);
    }
    if (totalAdvanceAmount != totalPayments) {
      return false;
    }
    const oweLeft = this.totalFiscalProductsValue - this.totalPayedIn - totalPayments;
    return oweLeft == 0 ? true : false;
  }

  ngOnDestroy(): void {
    for (const sub  of this.allSub) {
      if(!sub.closed) {
        sub.unsubscribe();
      }
    }
  }
}
