import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DynamicFormComponent} from '../components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';
import {DynamicFormModule} from '../components/dynamic-form-master/dynamic-form.module';
import {MaterialModule} from '../material.module';
import {CommonDialogComponent} from './dialog/common-dialog.component';
import {LtTableComponent} from './lt-table/lt-table.component';
import {RouterModule} from '@angular/router';
import { LtFilterComponent } from './lt-filter/lt-filter.component';
import { LtPaginationComponent } from './pagination/lt-pagination.component';
import { InlineSelectComponent } from './lt-inline-edit/inline-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralDataService } from './services/general-data.service';
import { ResponseMessageService } from './services/response-message.service';
import { SettingsService } from './services/settings.service';


@NgModule({
    imports: [
      MaterialModule,
      CommonModule,
      DynamicFormModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule
    ],
    declarations: [
      CommonDialogComponent,
      LtTableComponent,
      LtFilterComponent,
      LtPaginationComponent,
      InlineSelectComponent
    ],
    providers: [
      ResponseMessageService,
      SettingsService,
    ],
  exports: [
    CommonDialogComponent,
    DynamicFormComponent,
    LtTableComponent,
    LtFilterComponent,
    LtPaginationComponent,
    InlineSelectComponent,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SharedModule {
}
