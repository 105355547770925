<ng-container *ngIf="transaction">
  <div class="priznanica_cont">
    <table>
      <tr>
        <td>
          <h4>
            Priznanica #<span id="priznanica_refNo_1">{{transaction.increment_id}}-{{transaction.year}}</span>
          </h4>
        </td>
        <td>
          <h4>Datum: <span id="priznanica_datum_1">{{transaction.date}}</span></h4>
        </td>
        <td>
          <h4>
            Dokument izdao:
            <span id="priznanica_uposlenik_1" >{{transaction.userOfficial ? transaction.userOfficial +' (id:'+transaction.uId+')' : transaction.user}}</span>
          </h4>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <h4>IZNOS: <span id="priznanica_iznos_1">{{transaction.amount | currency:' ':'symbol':'2.2-4':'fr'}} {{transaction.currency}}</span></h4>
        </td>
        <td colspan="2">
          <h4 *ngIf="showTotalOwe">DUG: <span *ngFor="let oneCurrencyOwe of totalOwe; let i = index;">{{ i == 1 ? ' + ' : ''}} {{(oneCurrencyOwe?.owe >= 0 ? oneCurrencyOwe?.owe : 0) | currency:' ':'symbol':'2.2-4':'fr'}} {{oneCurrencyOwe.currencyName}}</span></h4>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <h4>
            Opis:
            <span id="priznanica_opis_1"
              >{{transaction.comment}}</span
            >
          </h4>
        </td>
      </tr>
    </table>
    <hr />
  </div>
</ng-container>