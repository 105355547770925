import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransactionTypeService {

  constructor() { }

  getTransactionTypes(){
    return [
        {
            id: 1,
            name: 'Gotovina',
            checked: false
        },
        {
            id: 2,
            name: 'Ček',
            checked: false
        }
    ];
  }
}
