import { GeneralDataService } from "../services/general-data.service"

const CURRENCY_OWE_LIMIT = {
    EUR : 1.28, //50 /117.5
    RSD : 150
}

export function getOweLimit(type: string){
    const sett = localStorage.getItem(GeneralDataService.AUR_SETTINGS_KEY);
    if (!sett) {
        return CURRENCY_OWE_LIMIT;
    }
    const settObject = JSON.parse(sett);
    let keyType = '';
    if (type === 'contract') {
        keyType = GeneralDataService.CONTRACT_OWE_LIMIT_KEY;
    } else if (type === 'insurance') {
        keyType = GeneralDataService.INSURANCE_OWE_LIMIT_KEY;
    }
    let oweLimit = {};
    if (keyType != '') {
        oweLimit = settObject[keyType] ?? null;
        if (!oweLimit) {
            return CURRENCY_OWE_LIMIT;
        }
        Object.keys(oweLimit).forEach(function(key, index) {
            oweLimit[key] = parseFloat(oweLimit[key]);
        });
    } else {
        oweLimit = CURRENCY_OWE_LIMIT;
        console.warn('Unknown limit type');
    }
    return oweLimit;
}