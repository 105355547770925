import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './../../material.module';

import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';
import { FormDatepickerComponent } from './components/form-datepicker/form-datepicker.component';
import { FormCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormInputHiddenComponent } from './components/form-input/form-input-hidden.component';
import { FormEventService } from './form-event.service';
import { FormAutocompleteComponent } from './components/form-autocomplete/form-autocomplete.component';
import { FormSelectChipComponent } from './components/form-select-chip/form-select-chip.component';
import { FormDaterangepickerComponent } from './components/form-datepicker/form-daterangepicker.component';
import { FormSeparatorComponent } from './components/form-separator/form-separator.component';
import { FormRadioComponent } from './components/form-radio/form-radio.component';

@NgModule({
    imports: [ 
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    declarations: [
        DynamicFieldDirective,
        DynamicFormComponent,
        FormButtonComponent,
        FormInputComponent,
        FormSelectComponent,
        FormCheckboxComponent,
        FormTextareaComponent,
        FormDatepickerComponent,
        FormDaterangepickerComponent,
        FormInputHiddenComponent,
        FormAutocompleteComponent,
        FormSelectChipComponent,
        FormSeparatorComponent,
        FormRadioComponent  
    ],
    providers: [
        //FormEventService
    ],
    exports: [
        DynamicFormComponent,
        DynamicFieldDirective
    ]
})
export class DynamicFormModule {}
