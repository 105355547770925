<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" /> 
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Priznanica</title>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap">
  </head>

  <body>
    <div id="template_cont" [ngClass]="receipts.length > 1 ? 'multiple-receipts' : '' ">
      <div class="print-menu no-print">
        <mat-checkbox color="primary" class="no-print checkbox-bordered" [formControl]="showOwe" (change)="showOweChanged($event)">Prikaži dug</mat-checkbox>
        <mat-checkbox matTooltip="Prikaži priznanicu u 2 primerka" color="primary" class="no-print checkbox-bordered" [formControl]="showMultipleCopies" (change)="numberOfCopiesChanged($event)">Dupla priznanica</mat-checkbox>
        <button mat-raised-button color="primary" (click)="print()">Štampaj</button>
      </div>
      <ng-container *ngFor="let j of receipts" >
        <div class="divider-line" *ngIf="j > 1"> </div>
        <header>
          <div class="logo-holder">
            <img [src]="logo" />
          </div>
          <div id="header_info_cont">
            <h4>{{companyName}} | <span>{{address}}</span></h4>
            <h4>
              PIB:<span> {{pib}} |</span> MB:
              <span>{{jib}} |</span> TR:
              <span>{{bankAccount}}</span>
            </h4>
            <h4><span>{{phone}} | {{email}}</span></h4>
          </div>
        </header>
        <app-receipt></app-receipt>
      </ng-container>
    </div>
    
  </body>
</html>
