import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DynamicFormComponent } from 'src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';
import { FiscalReceiptsEditComponent } from './fiscal-receipts-edit.component';
import { ContractService } from '../contract.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LT_DATE_RS, LT_DATE_US, formatDate, formatDatetime, todaysDate } from 'src/app/shared/helpers/date';
import { TransactionService } from 'src/app/transaction-new/transaction.service';
import { FiscalReceiptService } from './fiscal-receipt-service';
import { FormEventService } from 'src/app/components/dynamic-form-master/form-event.service';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';
import { FiscalReceiptPreviewComponent } from '../fiscal-receipt-preview/fiscal-receipt-preview.component';
import { FiscalInvoiceType } from './fiscal-invoice-type';
import { FiscalFinalGenerateComponent } from './fiscal-final-generate.component';
import { FiscalAdvanceGenerateComponent } from './fiscal-advance-generate.component';
import { CommonDialogComponent } from 'src/app/shared/dialog/common-dialog.component';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-fiscal-receipts-list',
    templateUrl: './fiscal-receipts-list.component.html',
    styleUrls: ['./fiscal-receipts-list.component.scss']
})
export class FiscalReceiptsListComponent implements OnInit {
    readonly STATUS_STORNO = 2;

    fiscalReceiptsConfig = [];
    receiptsFormatted: Array<any>;
    finalReceiptsData: Array<any>;
    advanceReceiptsData: Array<any>;
    manualReceiptsData: Array<any>;
    advanceTotals = {};
    fiscalCurrency = TransactionService.MAIN_CURRENCY.name;
    columns = [
        { name: 'number', label: 'Br. računa' },
        { name: 'date', label: 'Datum' },
        { name: 'referenceFiscalNumber', label: 'Referentni avansni fiskal' },
        { name: 'amount', label: 'Iznos', type: 'money-object' },
        { name: 'is_fiscalized_txt', label: 'Način unosa' },
        { name: 'is_saved_in_cis', type: 'button', label: 'Sačuvano u CIS' },
        { name: 'status', label: 'Status' },
        {
            name: 'edit',
            label: 'Akcije',
            type: 'button',
            actions: [
                {
                    type: 'event',
                    actionName: 'lt-edit-fiscal',
                    title: 'Izmeni fiskal',
                    icon: 'edit'
                },
                {
                    type: 'event',
                    actionName: 'lt-preview-fiscal',
                    name: '',
                    title: 'Pregled fiskala',
                    icon: 'visibility'
                }
            ]
        }
    ];
    finalColumns= [];
    advanceColumns= [];
    manualColumns= [];

    activeTab = 'final';

    @ViewChild(DynamicFormComponent) fiscalReceiptsForm: DynamicFormComponent;

    @Input() referenceItemId: number;
    @Input() receipts: Array<any>;
    @Input() fiscalAmount: '';
    @Input() amountsPerMoneyType;
    @Input() fiscalServiceName: '';
    @Input() cisContractId: '';

    constructor(
        public dialog: MatDialog,
        public contractService: ContractService,
        public fiscalService: FiscalReceiptService,
        private _snackBar: MatSnackBar,
        private formEventService: FormEventService,
        private respMsgService: ResponseMessageService
    ) {
    }

    ngOnInit(): void {
        this.finalColumns = this.columns.filter(col => {
            if (col.name == 'referenceFiscalNumber') {
                return false;
            }
            return true;
        });

        this.advanceColumns = this.columns.filter(col => {
            if (col.name == 'is_saved_in_cis') {
                return false;
            }
            return true;
        });

        this.manualColumns = this.columns.filter(col => {
            if (['is_saved_in_cis', 'referenceFiscalNumber'].includes(col.name)) {
                return false;
            }
            return true;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        //@todo check does it affect performance
        if (changes.receipts) {
            const defaultActions = this.columns.find(el => el.name == 'edit').actions;
            const addedToCisBtn = this.columns.find(el => el.name == 'is_saved_in_cis');
            this.finalReceiptsData = [];
            this.advanceReceiptsData = [];
            this.manualReceiptsData = [];
            this.advanceTotals = {};
            changes.receipts.currentValue.forEach(receipt => {
                let fiscalActions = [];
                //only for normal add send to cis option
                if ([FiscalInvoiceType.NORMAL, FiscalInvoiceType.MANUAL].includes(Number(receipt.fiscal_type))) {                    
                    fiscalActions = [{
                        type: 'event',
                        actionName: 'lt-send-to-cis',
                        title: 'Sačuvaj u CIS',
                        btnText: 'Sačuvaj u CIS',
                        icon: 'sync_alt',
                        color: 'primary',
                    }];
                }

                let currentDefaultActions = [...defaultActions];
                if (receipt.fiscal_type == FiscalInvoiceType.ADVANCED && !receipt.referenceFiscalNumber) {
                    if (!receipt.final_fiscal_id) {
                        currentDefaultActions.push({
                            type: 'event',
                            actionName: 'lt-add-to-advance',
                            title: 'Kreiraj novi avansni',
                            icon: 'add',
                        });
                    }
                }
                let oneReceipt = {
                    ...receipt,
                    date: formatDate(receipt.date),
                    amount: { //if changed pay attention used in sending to cis
                        currencyName: this.fiscalCurrency,
                        value: receipt.amount
                    },
                    status: receipt.status_id == this.STATUS_STORNO ? 'Refundiran' : 'Aktivan',
                    edit: {
                        actions: currentDefaultActions.filter(el => {
                            if (el.actionName == 'lt-preview-fiscal') {
                                return receipt.is_fiscalized == 1;
                            }
                            if (el.actionName == 'lt-edit-fiscal') {
                                return !(receipt.is_fiscalized == 1);
                            }
                            return true;
                        })
                    },
                    is_fiscalized_txt: receipt.is_fiscalized == 1 ? 'GENERISANO' : 'RUČNO',
                    is_saved_in_cis: receipt.is_saved_in_cis == 1 ?
                        {
                            actions: [{
                                type: 'event',
                                actionName: 'done',
                                btnText: 'Sačuvano',
                                icon: 'check',
                                disabled: true,
                                color: 'primary',
                            }]
                        } :
                        {
                            actions: fiscalActions
                        }

                }
                if (oneReceipt.fiscal_type == FiscalInvoiceType.ADVANCED) {
                    this.advanceReceiptsData.push(oneReceipt);
                    const advanceReceiptNumber = oneReceipt.number;
                    const advanceReceiptReference = oneReceipt.referenceFiscalNumber;
                    if (!advanceReceiptReference) {
                        if (!this.advanceTotals[advanceReceiptReference]) {
                            this.advanceTotals[advanceReceiptNumber] = 0;
                        }
                        this.advanceTotals[advanceReceiptNumber] += parseFloat(oneReceipt.amount.value);
                    } else {
                        this.advanceTotals[advanceReceiptReference] += parseFloat(oneReceipt.amount.value);
                    }

                    oneReceipt.number;

                } else if (oneReceipt.fiscal_type == FiscalInvoiceType.NORMAL) {
                    this.finalReceiptsData.push(oneReceipt);
                } else if (oneReceipt.is_fiscalized == '0') {
                    this.manualReceiptsData.push(oneReceipt);
                }
            });
        }
    }

    openDialogFiscal(data = { amount: '', date: null }) {
        data.amount = this.fiscalAmount;
        data.date = todaysDate(LT_DATE_US);
        const dialogRef = this.dialog.open(FiscalReceiptsEditComponent, {
            width: 'auto',
            data: data,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                result.reference_id = this.referenceItemId;
                result.cisContractId = this.cisContractId;
                (result.id ? this.contractService.updateFiscalReceipt(result) : this.contractService.addFiscalReceipt(result)).subscribe({
                    next: (resp: any) => {
                        if (resp.success) {
                            //if contract is not added to CIS it can be updated in CIS
                            if (!this.cisContractId) {
                                this._snackBar.open('Fiskalni račun je uspešno sačuvan u Aurori, ali NIJE U CIS-u', "", {
                                    duration: 5000,
                                    panelClass: ['orange-snackbar'],
                                });
                                this.emitFiscalUpdatedEvent(result.data);
                                return;
                            }
                            this.sendFiscalToCis(result);
                        }
                    },
                    error: (err) => {
                        this.showError(err?.error?.message);
                    }
                });
            }
        });
    }

    openFiscalPreview(data = { name: '', content: null }) {
        const dialogRef = this.dialog.open(FiscalReceiptPreviewComponent, {
            width: 'auto',
            data: data,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }

    onTableEvent(event) {
        if (event && event.name) {
            if (event.name == 'lt-edit-fiscal') {
                const fiscal = event?.data;
                const fiscalId = fiscal && fiscal?.id;
                if (fiscalId) {
                    this.fiscalService.findById(fiscalId).subscribe(res => {
                        if (res.success && res.data) {
                            this.openDialogFiscal(res.data);
                        } else {
                            this.showError('Desila se greska. Molimo pokusajte ponovo');
                        }
                    },
                        err => {
                            this.showError(err?.error?.message);
                        });
                }
            }
            if (event.name == 'lt-preview-fiscal') {
                const fiscal = event?.data;
                const fiscalId = fiscal && fiscal?.id;
                if (fiscalId) {
                    this.fiscalService.findById(fiscalId).subscribe(res => {
                        if (res.success && res.data) {
                            this.openFiscalPreview({ content: res.data.journal, name: res.data.number });
                        } else {
                            //this.showError('Desila se greska. Molimo pokusajte ponovo');
                        }
                    },
                        err => {
                            this.showError(err?.error?.message);
                        });
                }
            }

            if (event.name == 'lt-send-to-cis') {
                const fiscal = event?.data;
                if (!fiscal) {
                    this.respMsgService.showError('Došlo je do greške. POdaci fiskala nisu pronađeni');
                    return false;
                }
                const fiscalId = fiscal?.id;
                if (fiscalId) {
                    this.fiscalService.findById(fiscalId).subscribe(res => {
                        if (res.success && res.data) {
                            const response = res.data;
                            const fiscalAmount = response.amount;
                            const fiscalNumber = response.number;
                            const fiscalDate = response.date;
                            if (fiscal.amount.value != fiscalAmount || fiscal.number != fiscalNumber || formatDatetime(fiscal.date, LT_DATE_RS, LT_DATE_US) != fiscalDate) {
                                this.respMsgService.showError('Podaci fiskala su promenjeni u međuvremenu. Molimo osvežite stranicu.');
                            } else {
                                this.sendFiscalToCis({
                                    amount: fiscalAmount,
                                    date: fiscalDate,
                                    number: fiscalNumber
                                });
                            }
                        } else {
                            this.respMsgService.showError('Došlo je do greške. Negativan odgovor');
                        }
                    },
                        err => {
                            this.showError(err?.error?.message);
                        });
                } else {
                    this.respMsgService.showError('Fiskal ID atribut nije pronadjen');
                }
            }

            if (event.name == 'lt-add-to-advance') {
                this.openAdvanceFiscalDialog({
                    referentAdvanceFiscal: event.data?.number ?? null,
                    fiscalItems: event.data?.product_items ?? [],
                    //totalPayedInAdvance: event.data?.totalPayedInAdvance.length > 0 ?
                        //event.data.totalPayedInAdvance[0]?.total : 0,
                    totalPayedInAdvance: this.advanceTotals[event.data?.number] ?? 0,
                    amountOnFirstAdvanceFiscal: event.data?.amount ? event.data?.amount?.value : 0,
                    referenceItemId: this.referenceItemId
                });
            }
        }
        //ltTableColumnsHidden
    }

    emitFiscalUpdatedEvent(data) {
        this.formEventService.eventEmitter$.next({
            message: 'lt-contract-fiscal-updated',
            el: null,
            value: data
        });
    }

    setActiveTab(activeTabName) {
        this.activeTab = activeTabName;
    }

    sendFiscalToCis(fiscalData: {
        amount: number,
        date: string,
        number: string,
    }) {
        this.contractService.saveCisPaymentData(fiscalData).subscribe({
            next: (resp: any) => {
                this._snackBar.open('Fiskalni račun je uspešno sačuvan u CIS-u', "", {
                    duration: 3000,
                    panelClass: ['success-snackbar'],
                });
                this.emitFiscalUpdatedEvent(resp.data);
            },
            error: (err) => {
                this.showError(err?.error?.message);
                this.emitFiscalUpdatedEvent(true);
            }
        });
    }

    showError(message: string, action = null) {
        this._snackBar.open(message, action, {
            duration: 5000,
            panelClass: ['red-snackbar']
        });
    }

    openFinalFiscalDialog() {
        let data: {
            unitPrice?: '',
            name?: '',
            quantity?: 1,
            transactionType?: null | number, //@todo add it in dialog itself
            paymentType?: null,
        } = {};
        data.unitPrice = this.fiscalAmount;
        data.name = this.fiscalServiceName;
        data.quantity = 1;
        data.transactionType = 0; //sale
        data.paymentType = this.amountsPerMoneyType; //default cache
        const dialogRef = this.dialog.open(FiscalFinalGenerateComponent, {
            width: 'auto',
            data: data,
        });
        dialogRef.afterClosed().subscribe(responseData => {
            if (responseData) {
                responseData.contractId = this.referenceItemId;
                responseData.cisContractId = this.cisContractId;

                this.fiscalService.generateFiscal(responseData).subscribe(
                    resp => {
                        let responseData = resp?.data;
                        if (resp.success && responseData) {
                            responseData.cisContractId = this.cisContractId;
                            this.respMsgService.showSuccess('Fiskal uspešno kreiran');
                            //this.sendFiscalToCis(responseData); @todo uncoment
                        }
                    }, err => {
                        if (err?.error?.message != undefined) {
                            this.showError(err?.error?.message, false);
                        } else {
                            this.showError('Nepoznata greška');
                        }
                    }
                ).add(() => {
                    //this.sendingToCis = false;
                });
                return;
            }
        });
    }

    openAdvanceFiscalDialog(data : {
        type?: 'start' | null,
        unitPrice?: '',
        name?: '',
        quantity?: 1,
        invoiceType?: null | number,
        transactionType?: null | number,
        paymentType?: null,
        referentAdvanceFiscal?: string | null,
        fiscalItems?: Array<any> | null,
        totalPayedInAdvance?: Array<any>,
        amountOnFirstAdvanceFiscal?: number,
        referenceItemId?: number
    }  ) {
        const openNewAdvanceScenario = data?.type && data.type == 'start' ? true: false;
        //when opening new advance transaction data is empty
        if (openNewAdvanceScenario) {
            data = {
                unitPrice: this.fiscalAmount,
                name: this.fiscalServiceName,
                quantity: 1,
                transactionType: 0,
                invoiceType: FiscalInvoiceType.ADVANCED,
                referenceItemId: this.referenceItemId
            };
        }

        if (openNewAdvanceScenario && this.advanceReceiptsData.length > 0) {
            console.log('Here');
            let dialogRef = this.dialog.open(CommonDialogComponent, {
                width: '450px',
                data: {
                  title: 'Nova avansna transakcija',
                  text: 'Već postoji otvorena avansna transakcija i preporučeno je da se ostatak uplata veže za nju. Da li ste sigurni da želite da kreirate novu?',
                  confirmBtn: 'Kreiraj',
                  cancelBtn: 'Nazad',
                  confirmClass: 'green-btn',
                  cancelClass: 'red-btn',
                }
              });
          
            dialogRef.afterClosed().subscribe(response => {
                if (response !== true) {
                    return false;
                }
                this.openMainAdvanceDialog(data);
            });
        } else {
            this.openMainAdvanceDialog(data);
        }
    }

    openMainAdvanceDialog(data) {
        const dialogRef = this.dialog.open(FiscalAdvanceGenerateComponent, {
            width: 'auto',
            data: data,
        });
        dialogRef.afterClosed().subscribe(responseData => {
            if (responseData?.success) {
                if (responseData.isFinal) {
                    this.sendFiscalToCis({
                        amount: responseData.data.amount,
                        number: responseData.data.number,
                        date: responseData.data.date,
                    });
                } else {
                    this.emitFiscalUpdatedEvent(responseData.data);
                }
            }
        });
    }
}
