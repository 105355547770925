import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { FieldConfig } from '../../models/field-config.interface';

@Component({
  exportAs: 'dynamicForm',
  selector: 'dynamic-form',
  styleUrls: ['dynamic-form.component.scss'],
  template: `
    <form
      [class]="{
      'dynamic-form': true,
      'dynamic-form--row': !cols,
      'dynamic-form--grid': cols,
      'dynamic-form--grid-2': cols === 2,
      'dynamic-form--grid-3': cols === 3,
      'dynamic-form--grid-4': cols === 4,
      'dynamic-form--grid-5': cols === 5,
      'dynamic-form--grid-6': cols === 6
      }"
      [formGroup]="form"
      (submit)="handleSubmit($event)">
      <ng-container
        *ngFor="let field of config;"
        dynamicField
        [config]="field"
        [group]="form">
      </ng-container>
    </form>
  `
})
export class DynamicFormComponent implements OnChanges, OnInit {
  @Input()
  config: FieldConfig[] = [];

  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();

  @Input() form: UntypedFormGroup;

  @Input() cols: 2 | 3 | 4 | 5 | 6 | null = null;
  @Input() doValidation = false;

  get controls() { return this.config.filter(({type}) => type !== 'button'); }
  get changes() { return this.form.valueChanges; }
  get valid() { return this.form.valid; }
  get value() { return this.form.value; }

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    // if we don't pass parent form create a new one
    if(!this.form) {
      this.form = this.createGroup();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //@todo check does it affect performance
    if (changes.config && this.form) {
      for (const k in changes.config.currentValue) {
        const config = changes.config.currentValue[k];;
        const type = config.type;
          if (this.isDateRange(type)) {
            const startName = config.startName;
            const endName = config.endName;
            this.setValue(startName, config.value[startName]);
            this.setValue(endName, config.value[endName]);
          } else {
            this.setValue(config.name, config.value);
          }
      }
    }

    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const configControls = this.controls.map((item) => item.name);

      controls
        .filter((control) => !configControls.includes(control))
        .forEach((control) => this.form.removeControl(control));

      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.config.find((control) => control.name === name);
          this.addControl(this.form, config);
        });

    }

  }

  addControl(group, config) {
    if (this.isDateRange(config.type)) {
      group.addControl(config.endName, this.createControl(config, config.endName));
      group.addControl(config.startName, this.createControl(config,config.startName));
    } else {
      group.addControl(config.name, this.createControl(config));
    }
  }

  isDateRange(type: string) {
    return type == 'daterangepicker';
  }

  createGroup() {
    const group = this.fb.group({});
    this.controls.forEach(control => {
       this.addControl(group, control);
    });
    return group;
  }

  createControl(config: FieldConfig, newName: string = '') {
    let { disabled, validation, value} = config;
    if (this.isDateRange(config.type)) {
      value = config.value[newName];
    }
    const validatorsList = this.bindValidations(validation || []);
    return this.fb.control({ disabled, value }, validatorsList);
  }

  handleSubmit(event: Event) {
    this.form.markAllAsTouched();
    event.preventDefault();
    event.stopPropagation();
    this.submit.emit(this.value);
  }

  setDisabled(name: string, disable: boolean) {
    if (this.form.controls[name]) {
      const method = disable ? 'disable': 'enable';
      this.form.controls[name][method]();
      return;
    }

    this.config = this.config.map((item) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  setValue(name: string, value: any) {
    if (this.form.controls[name] !== undefined) {
      this.form.controls[name].setValue(value, {emitEvent: true});
    }
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        //validList.push(valid.validator);
        if (this.doValidation) {
          validList.push(valid);
        }
      });
      return Validators.compose(validList);
    }
    return null;
  }
}
