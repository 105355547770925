import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {AbstractInputComponent} from '../abstract-input.component';
import { FormEventService } from '../../form-event.service';

@Component({
  selector: 'form-separator',
  template: `<h3>{{config.label}}</h3>`
})
export class FormSeparatorComponent extends AbstractInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;

  constructor(private formEventService: FormEventService) {
    super();
  }
}
 