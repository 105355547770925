    <style>
        .row-action {
            text-decoration: none;
        }

        .fab-container {
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 100;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

            > div {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                margin-bottom: 5px;

                button {
                margin-bottom: 17px;
                }
            }
        }

        .fab-toggler {
        float: right;
        z-index: 100;
        }
    .extended-table-checkbox {
        padding: 10px;
    }
</style>
<mat-progress-bar *ngIf="isSearching" mode="indeterminate"></mat-progress-bar>
<app-lt-filter *ngIf="filterConfig.length > 0" 
    [filterConfig]="filterConfig" 
    [inlineConfig]="inlineConfig"
    (filterChanged)="onFilterChanged($event)"></app-lt-filter>
    <!--<mat-checkbox class="extended-table-checkbox form-input form-input--col-12" [(ngModel)]="tableOptions.expandable" (ngModelChange)="showHideExtendedView()">
        Prošireni pregled
    </mat-checkbox>-->

<app-lt-table
    [columns]="columns"
    [toolbarButtons]="toolbarButtons"
    [dataSource]="dataSource"
    [options]="tableOptions"
    (tableEvent)="onTableEvent($event)">
</app-lt-table>
<app-lt-pagination
    [totalCount]="totalRowsCount"
    [pageSize]="contractPageSize"
    [pageSizeOptions]="pageSizeOptions"
    (paginationEvent)="paginationChanged($event)"></app-lt-pagination>
<div class="fab-container">
    <a [routerLink]="['/contract/new']">
        <button mat-fab class="fab-toggler" color="warn">
            <i class="material-icons">add</i>
        </button>
    </a>
</div>
    
    <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->