<style>
   table{
      width: 100%;
  }
</style>
<mat-toolbar >    
  <mat-toolbar-row>
      <span>Arhiva <span *ngIf="currentPeriod">za {{currentPeriod.date | date : 'dd.MM.yyyy' }}</span></span>
      <span class="example-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>

<p><a [routerLink]="PERIODS_PATH">Nazad na Arhivu</a></p>

<table mat-table [dataSource]="dataSource" matSort>
  <ng-container *ngFor="let column of columns" matColumnDef="{{column.name}}">
    <ng-container *ngIf="column.name != 'actions'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{column.label}}
        </th>
    </ng-container>
    <ng-container *ngIf="column.name == 'actions'">
        <th mat-header-cell *matHeaderCellDef>
            
        </th>
    </ng-container>
   
     <!-- Footer cell -->
     <ng-container *ngIf="column.type === 'money'">
        <td mat-footer-cell *matFooterCellDef>{{totals[column.name] | currency:' ':'symbol':'2.2-4':'fr'}}</td>
    </ng-container>
    <ng-container *ngIf="!column.type ">
        <td mat-footer-cell *matFooterCellDef>{{totals[column.name]}}</td>
    </ng-container>
  <!-- END Footer cell -->

    
    <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="column.name != 'actions'">
            <div *ngIf="column.type && column.type == 'money'">
                {{element[column.name] | currency:' ':'symbol':'2.2-4':'fr'}}
            </div>
            <div *ngIf="!column.type">
                {{element[column.name]}}
            </div>
        </ng-container>
        <div *ngIf="column.name == 'actions'">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <a class="row-action" *ngFor="let action of column.actions" target="_blank" [routerLink]="[action.path+element[action.param]]">
                    <button mat-menu-item>
                            <mat-icon>{{action.icon}}</mat-icon>
                            <span>{{action.name}}</span>
                    </button>
                </a>
            </mat-menu>
        </div>          
    </td>
  </ng-container>
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  <br>
  <h4>Gotovina na početku dana RSD : {{transactionsOverview.start_cash  | currency:' ':'symbol':'2.2-4':'fr'}}</h4>
  <h4>Ček na početku dana RSD : {{transactionsOverview.start_cek  | currency:' ':'symbol':'2.2-4':'fr'}}</h4>
  <h4>Saldo na početku dana RSD:  {{transactionsOverview.start_total  | currency:' ':'symbol':'2.2-4':'fr'}}</h4>
  <h4>Gotovina na kraju dana RSD:  {{transactionsOverview.end_cash  | currency:' ':'symbol':'2.2-4':'fr'}}</h4>
  <h4>Ček na kraju dana RSD:  {{transactionsOverview.end_cek  | currency:' ':'symbol':'2.2-4':'fr'}}</h4>
  <h4>Saldo na kraju dana RSD:  {{transactionsOverview.end_total  | currency:' ':'symbol':'2.2-4':'fr'}}</h4>