import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators} from '@angular/forms';
import { Organizer } from '../organizer';
import { prepareFormGroup } from '../../shared/helpers/form';

@Component({
  selector: 'app-organizer',
  templateUrl: './organizer.component.html',
  styleUrls: ['./organizer.component.css']
})
export class OrganizerComponent implements OnInit {

  @Input() organizer: Organizer | null = null;
  @Input() organizerForm = new UntypedFormGroup({});

  organizerConfig = [];

  constructor() { }

  ngOnInit(): void {
    this.setOrganizerFormConfig(this.organizer);
  }

  setOrganizerFormConfig(values: Organizer | null) {
    this.organizerConfig = [
      {
        type: 'input',
        label: 'Oranizator',
        name: 'name',
        placeholder: 'Oranizator',
        validation: [Validators.required],
        value: values?.name || ''
      },
      {
        type: 'input',
        label: 'Br. računa organizatora',
        name: 'account_no',
        placeholder: 'Br. računa organizatora',
        validation: [Validators.required],
        value: values?.account_no || ''
      },
      {
        type: 'input',
        label: 'Br. licence organizatora',
        name: 'license_no',
        placeholder: 'Br. licence organizatora',
        validation: [Validators.required],
        value: values?.license_no || ''
      },
      {
        type: 'input',
        label: 'Kategorija licence',
        name: 'license_category',
        placeholder: 'Kategorija licence',
        value: values?.license_category || ''
      },
      {
        type: 'input',
        label: 'Matični broj',
        name: 'jib',
        placeholder: 'Matični broj',
        validation: [Validators.required],
        value: values?.jib || ''
      },
      {
        type: 'input',
        label: 'PIB',
        name: 'pib',
        placeholder: 'PIB',
        //validation: [Validators.required],
        value: values?.pib || ''
      },
      {
        type: 'datepicker',
        label: 'Datum licence organizatora',
        name: 'license_date',
        placeholder: 'Datum licence organizatora',
        // validation: [Validators.required],
        value: values?.license_date || ''
      },
      {
        type: 'input',
        label: 'Kontakt organizatora',
        name: 'tel',
        placeholder: 'Kontakt organizatora',
        // validation: [Validators.required],
        value: values?.tel || ''
      },
      {
        type: 'input',
        label: 'Email organizatora',
        name: 'email',
        placeholder: 'Email organizatora',
        // validation: [Validators.required],
        value: values?.email || ''
      },
      {
        type: 'input',
        label: 'Web stranica organizatora',
        name: 'url',
        placeholder: 'Web stranica organizatora',
        // validation: [Validators.required],
        value: values?.url || ''
      },
      {
        type: 'input',
        label: 'Ulica',
        name: 'address',
        placeholder: 'Ulica',
        validation: [Validators.required],
        value: values?.address || ''
      },
      {
        type: 'input',
        label: 'Grad',
        name: 'city',
        placeholder: 'Grad',
        validation: [Validators.required],
        value: values?.city || ''
      },
      {
        type: 'input',
        label: 'Poštanski br.',
        name: 'postcode',
        placeholder: 'Poštanski br.',
        validation: [Validators.required],
        value: values?.city || ''
      },
      {
        type: 'input',
        label: 'Osoba za reklamacije',
        name: 'complaint_person',
        placeholder: 'Osoba za reklamacije',
        // validation: [Validators.required],
        value: values?.complaint_person || ''
      },
      {
        type: 'input',
        label: 'Broj za reklamacije',
        name: 'complaint_tel',
        placeholder: 'Broj za reklamacije',
        // validation: [Validators.required],
        value: values?.complaint_tel || ''
      },
      {
        type: 'checkbox',
        label: 'Aktiviran',
        name: 'is_active',
        placeholder: 'Aktiviran',
        value: values?.is_active || false
      },
    ];

    this.organizerForm = prepareFormGroup(this.organizerConfig, this.organizerForm);
  }

}
