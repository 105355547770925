import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AgencySettingsService } from '../settings/agency-settings/agency-settings.service';
import { ResponseMessageService } from '../shared/services/response-message.service';
import { UntypedFormControl } from '@angular/forms';
import { FormEventService } from '../components/dynamic-form-master/form-event.service';
import { SettingsService } from '../shared/services/settings.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  providers: [AgencySettingsService]
})
export class PrintComponent implements OnInit {
  logo = '';
  agencyId = 1;
  companyName = '';
  address = '';
  pib = '';
  jib = '';
  bankAccount = '';
  phone = '';
  email = '';
  showOwe = new UntypedFormControl(0);
  showMultipleCopies = new UntypedFormControl(0);
  allSub = [];
  receipts = [1];

  constructor(
    private agencySettingsService: AgencySettingsService,
    private responseMessageService: ResponseMessageService,
    private formEventService: FormEventService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.setReceipts(this.showMultipleCopies.value);

    this.settingsService.get(this.settingsService.SHOW_OWE_ON_PAYMENT_RECEIPT).subscribe(resp => {
      this.showOwe.setValue(resp?.data?.value ? Number(resp.data.value) : 0);
      this.showOweChanged({checked: this.showOwe.value});
    });
    this.agencySettingsService.findById(this.agencyId).subscribe(
      resp => {
        const agencyInfo = resp?.data?.agencySettings ?? {};
        this.logo = environment.logos + agencyInfo.logo_url;
        this.companyName = agencyInfo.name;
        this.address = agencyInfo.address + ', ' + agencyInfo.city;
        this.pib = agencyInfo.pib;
        this.jib = agencyInfo.jib;
        this.bankAccount = agencyInfo.account_no;
        this.phone = agencyInfo.tel;
        this.email = agencyInfo.email;
      }, err => {
        this.responseMessageService.showError(err?.error?.message);
      });
  }

  showOweChanged(event) {
    this.formEventService.eventEmitter$.next({ message: 'lt-show-receipt-owe', value: event.checked });
  }

  print() {
      window.print();
    //this.formEventService.eventEmitter$.next({ message: 'lt-receipt-print', value: true });
  }

  numberOfCopiesChanged(e) {
    this.setReceipts(e.checked);
  }

  setReceipts(multi) {
    if (multi) {
      this.receipts = [1,2];
    } else {
      this.receipts = [1];
    }
  }

}