import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransactionService } from '../transaction.service';
import { FormEventService } from 'src/app/components/dynamic-form-master/form-event.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
  transactionId : number = null;
  transaction = null;
  currenciesMap = new Map();
  allSub = [];
  showTotalOwe = false;
  totalOwe = null;
  readonly MAIN_CURRENCY_NAME = TransactionService.MAIN_CURRENCY.name;

  constructor(
    private route: ActivatedRoute,
    private transactionService: TransactionService,
    private formEventService: FormEventService
  ) { }

  ngOnInit() {
    this.transactionService.getCurrencies().subscribe(currencies => {
      currencies.forEach(currency => {
        this.currenciesMap.set(currency.id, currency);
      });
    });
    this.allSub.push(
      this.formEventService.eventEmitter$.subscribe(event => {
        if (event.message == 'lt-show-receipt-owe') {
          this.showTotalOwe = event.value;
        }
        /*
        if (event.message == 'lt-receipt-print') {
          window.print();
        }
          */
      }
      )
    );
    this.allSub.push(
      this.route.params.subscribe(params => {
          let filter = {
              id : params.id
          };
          this.transactionService.find(filter).subscribe( res => {
            if (res['data'] && res['data']['data'][0]) {
              this.transaction = res['data']['data'][0]; 
              this.transaction.date = formatDate(new Date(this.transaction.timestamp), 'dd.MM.yyyy', 'en');
              this.transaction.currency = this.currenciesMap.get(Number(this.transaction.currency))?.name ?? '';
              this.totalOwe = res['data']['totalOwePerCurrency'];
              this.totalOwe = this.totalOwe.map(oneCurrency => {
                return {
                  ...oneCurrency,
                  currencyName: this.currenciesMap.get(Number(oneCurrency.currency))?.name ?? ''
                }
              })
            }
          });
      })
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.allSub) {
        if (!sub.closed) {
            sub.unsubscribe();
        }
    }
  }
}
