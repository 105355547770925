import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from './../../environments/environment';

import { User } from '../_models';
import {GlobalService} from '../model/global.service';
import { GeneralDataService } from '../shared/services/general-data.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    returnUrl = '';
    loggedIn = false;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        const headers = GlobalService.getHeaders();
        return this.http.post<any>(environment.apiHost + '/user/login', 
            JSON.stringify({
                LoginForm: {
                    username: username,
                    password: password
                }
            }),
            {
                headers: headers
            })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user.success) { 
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    //console.log(user.data);
                    localStorage.setItem(environment.tokenName, user.data.access_token);
                    this.loggedIn = true;
                } else {
                    //console.log(user);
                    this.loggedIn = false;
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(environment.tokenName);
        localStorage.removeItem('LtMotherToken');
        localStorage.removeItem(GeneralDataService.AUR_SETTINGS_KEY);
        localStorage.clear();
    }
}