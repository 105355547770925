<div class="form-filter__wrapper flex_col" [ngClass]="cssClass">
    <div class="form-filter__group">
        <dynamic-form [cols]="cols" [form]="filterForm" [config]="filterConfig"></dynamic-form>
    </div>
    <div class="inline_form_holder">
        <div class="inline_form">
            <dynamic-form *ngIf="inlineConfig.length" [form]="inlineForm" [config]="inlineConfig"></dynamic-form>
        </div>
        <div class="action_buttons">
            <button mat-flat-button (click)="filterForm.reset()"><mat-icon>refresh</mat-icon>Resetuj</button>
            <button mat-raised-button color="primary"
                (click)="submitSearch()"><mat-icon>search</mat-icon>Pretraži</button>
        </div>
    </div>
</div>