import { Arrangment } from './arrangment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from './../../environments/environment';
import { ArrangmentFilter } from './arrangment-filter';
import { ResponseBody } from '../model/response-body';

@Injectable()
export class ArrangmentService {
    url = environment.apiHost + '/lider/arrangment';
    headers = {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
    };

    constructor(private http: HttpClient) {
    }

    dataList: Arrangment[] = [];
  
    findById(id: number): Observable<Arrangment> {
        let params = {};
        let headers = this.headers;
        return this.http.get<Arrangment>(this.url + '/' + id, {params, headers});
    }
    
    load(filter: ArrangmentFilter): void {
        this.find(filter).subscribe(
            result => {
                this.dataList = result['data'];
            },
            err => {
                console.error('error loading', err);
            }
        )
    }

    find(filter: ArrangmentFilter): Observable<any> {

        let params = {
            sort: '-id'
        };
        if(filter.name){
            params['name'] = filter.name;
            params['operator'] = filter.operator;
        }
        if (filter['id']) {
            params['id'] = filter['id'];
        }
        //params['page'] = data['page'];
        //params['per-page'] = data['perPage'];
        let headers = this.headers;

        return this.http.get<Arrangment[]>(this.url, {params, headers});
    }

    searchFromExistingArrangements(searchString: string): Observable<any> {
        let params = {searchString};
        let headers = this.headers;
        return this.http.get<any>(this.url + '/get-from-contracts', {params, headers});
    }

    save(entity: Arrangment): Observable<Arrangment> {
        let headers = this.headers;
        return this.http.post<Arrangment>(this.url, entity, {headers});
    }
}

