<style>
    :host ::ng-deep .lt-table-button-container .lt-col-settings .mat-menu-content {
  background: lightgray!important;
  margin-top: .3rem;
}
.lt-col-settings .mat-menu-content {
  background: lightgray!important;
  margin-top: .3rem;
}
.lt-table-button-container .mat-menu-content{
    background-color: red;
}

.lt-col-settinmat-checkbox {
  width: 100%;
}
.entry-city{
    min-width: 80px!important;
}

</style>
<div *ngIf="toolbarButtons.length || this.options?.hasHideShowCol" class="lt-table-button-container">
    <div class="left-bar">
        <ng-container *ngFor="let button of toolbarButtons; let j = index;">
            <button [color]="button.color" mat-raised-button (click)="barButtonClick(button?.clickAction)">
                <mat-icon *ngIf="button.icon" >{{button.icon}}</mat-icon> {{button.label}}
                <span *ngIf="button.badge" 
                    [matBadge]="button.badge.text"
                    [matBadgeHidden]="button.badge?.hidden"
                    [matBadgeSize]="button.badge?.size ?? 'medium'"
                    matBadgeOverlap="false" 
                    [matBadgeColor]="button.badge?.color ?? 'warn'" ></span>
            
            </button>
        </ng-container>
    </div>
    <div class="right-bar">
        <ng-container *ngIf="this.options?.hasHideShowCol">
            <button matTooltip="Prikaži / Sakrij kolone tabele" #matMenuTrigger mat-raised-button [matMenuTriggerFor]="menu" 
                (menuOpened)="onColumnSettingMenuOpened()"
                (menuClosed)="onColumnSettingMenuClosed()">
                <mat-icon class="column-settings">view_column_2</mat-icon>
            </button>
            <!-- #enddocregion mat-menu-trigger-for -->
            <mat-menu class="lt-col-settings" #menu="matMenu">
                <b>Prikaži / Sakrij kolone</b>
            <mat-checkbox color="warn" #menuItems
                *ngFor="let column of columns; let i = index;"
                name="{{column.name}}"
                [checked]="column.isVisible"
                (click)="$event.stopPropagation()"
                (change)="columnSettingChanged($event)"
                >{{column.label}}</mat-checkbox>
            </mat-menu>
        </ng-container>
    </div>
</div>
<div class="table-scroller">
    <table 
        mat-table matSort *ngIf="dataSource" 
        multiTemplateDataRows 
        [dataSource]="dataSource"
        (matSortChange)="sortData($event)" 
        class="general-table">
        <ng-container *ngFor="let column of columns; let colIdx = index" matColumnDef="{{column.name}}">

            <ng-container *ngIf="column.name != 'actions'">
                <th mat-header-cell [mat-sort-header]="column.sort ? column.name : null" 
                [disabled]="column.sort === undefined"
                *matHeaderCellDef 
                [ngClass]="column.cssClass"
                [class.align-right]="column.type && (column.type == 'money' || column.type == 'money-list' || column.type == 'money-object')">
                    {{column.label}}
                </th>
            </ng-container>

            <ng-container *ngIf="column.name == 'actions'">
                <th mat-header-cell *matHeaderCellDef>
                    <a *ngIf="column.headerPath" [routerLink]="[column.headerPath]"
                    mat-mini-fab color="primary"
                    matTooltip="{{column.tooltip.title}}"
                    [matTooltipPosition]="(column.tooltip?.position || 'below')">
                        <i class="material-icons">add</i>
                    </a>
                </th>
            </ng-container>

            <!-- Footer cell -->
            <ng-container *ngIf="footerTotals && footerTotals[column.name] && column.type === 'money'">
                <td mat-footer-cell *matFooterCellDef class="align-right">
                    <span style="display:block;text-align:right;padding-right: 10px;">Ukupno: {{footerTotals[column.name] | currency:' ':'symbol':'2.2-4':'sr'}}</span>
                </td>
            </ng-container>
            <ng-container *ngIf="footerTotals && footerTotals[column.name] && !column.type">
                <td mat-footer-cell *matFooterCellDef>{{footerTotals[column.name]}}</td>
            </ng-container>
            <ng-container *ngIf="footerTotals && footerTotals[column.name] && column.type === 'money-list'">
                <td mat-footer-cell *matFooterCellDef class="align-right">
                    <ng-container *ngFor="let listItem of footerTotals[column.name]">
                        {{ listItem.value | currency:' ':'symbol':'2.2-4':'sr' }} {{listItem.currencyName}}<br>
                    </ng-container>
                </td> 
            </ng-container>
            <ng-container *ngIf="!footerTotals || !footerTotals[column.name]">
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- END Footer cell -->

            <td mat-cell *matCellDef="let element;let rowIdx = dataIndex"
                [ngClass]="element[column.name]?.class ?? column?.className || ''" >
                <ng-container *ngIf="column.name != 'actions'">
                    <div *ngIf="column.type && column.type == 'money'">
                    <span class="align-right" style="display:block;text-align:right;padding-right: 10px;">
                        {{element[column.name] | currency:' ':'symbol':'2.2-4':'sr'}}
                    </span>
                    </div>
                    <div [ngClass]="element[column.name]?.class ?? column?.className || ''" *ngIf="column.type && column.type == 'money-object'">
                        <span class="align-right" style="display:block;text-align:right;padding-right: 10px;">
                            {{element[column.name].value | currency:' ':'symbol':'2.2-4':'sr'}} {{element[column.name]?.currencyName}}
                            {{element[column.name]?.error}}
                        </span>
                    </div>
                    <div *ngIf="column.type && column.type == 'date'">
                        {{element[column.name] | date: 'dd.MM.yyyy'}}
                    </div>
                    <div (click)="onCellClick(rowIdx, colIdx, {element: element[column.name], row: element})" *ngIf="column.type && column.type == 'dynamicComponent'">
                        <div class="editable-{{rowIdx +'-'+ colIdx}}" #cellElem></div>
                        <span *ngIf="activeInlineIndex != rowIdx + '-' + colIdx ">{{element[column.name].text}}</span>
                    </div>
                    <div *ngIf="column.type && column.type == 'list'">
                        <ng-container *ngFor="let listItem of element[column.name]">
                            {{ listItem }} <br>
                        </ng-container>
                    </div>
                    <div class="align-right" [ngClass]="element[column.name]?.class" *ngIf="column.type && column.type == 'money-list'">
                        <ng-container *ngFor="let listItem of element[column.name]">
                            {{ listItem.value | currency:' ':'symbol':'2.2-4':'sr' }} {{listItem.currencyName}}<br>
                        </ng-container>
                    </div>
                    <div *ngIf="column.type && column.type == 'checkbox-action'">
                        <mat-checkbox [ngClass]="element[column.name]?.classCheckbox ?? (column?.classCheckbox ?? '')" [disabled]="element[column.name]?.disabled" (change)="emitEvent(column.actionName, element, $event)" [checked]="element[column.name].value">{{element[column.name]?.label}}</mat-checkbox>
                    </div>
                    <div *ngIf="column.type && column.type == 'url'">
                        <ng-container *ngIf="element[column.name]?.href === undefined">
                            <a *ngIf="!element[column.name]?.disabled" [routerLink]="column?.url?.route + (element[column.name]?.idParam ?? '')" [queryParams]="element[column.name]?.params" >
                                <mat-icon *ngIf="column?.icon">{{column.icon}}</mat-icon>
                                {{element[column.name]?.text ?? '__'}}</a>
                        </ng-container>
                        <ng-container *ngIf="element[column.name]?.href !== undefined">
                            <a href="{{element[column.name]?.href}}" target="{{element[column.name]?.target ?? '_blank'}}">{{element[column.name]?.text ?? element[column.name].href}}</a>
                        </ng-container>
                    </div>
                    <div *ngIf="column.type && column.type == 'chip' && element[column.name]?.text">
                            <div class="lt-table-label" [ngClass]="element[column.name]?.class"><mat-icon *ngIf="element[column.name]?.icon">{{element[column.name].icon}}</mat-icon>{{element[column.name]?.text ?? ''}}</div>
                    </div>
                    <div *ngIf="column.type && column.type == 'icon'">
                        <mat-icon [ngClass]="element[column.name]?.iconClass">{{element[column.name].iconName}}</mat-icon> 
                    </div>
                    <div *ngIf="column.type && column.type == 'input-extended'" [ngClass]="element[column.name]?.class">
                        {{element[column.name].text}} 
                    </div>
                    <mat-checkbox *ngIf="column.type === 'select-row'"
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)"
                        color="primary">
                    </mat-checkbox>
                    <div *ngIf="column.type && column.type == 'action-button'">
                        <button (click)="emitEvent(element[column.name].eventName ?? column.eventName, element)">{{element[column.name].label}}</button>
                    </div>
                    <a *ngIf="column.type == 'icon-link'" [target]="element[column.name]?.target ?? '_blank'" [routerLink]="[column.settings.path+element[column.settings.param]]">
                        <button mat-raised-button [color]="element[column.name]?.color ??(column?.settings?.color ?? 'primary') ">
                            <mat-icon>{{column.settings.icon}}</mat-icon>
                            <span>{{column.settings.iconText}}</span>
                        </button>
                    </a>
                    <div *ngIf="!column.type">
                        {{element[column.name]}}
                    </div>
                </ng-container>
                <ng-container *ngIf="column.name == 'button' || column.type == 'button'">
                    <ng-container *ngFor="let action of (element[column.name]?.actions ? element[column.name].actions : column.actions)">
                        <a *ngIf="!action.type" class="row-action" [routerLink]="[action.path+element[action.param]]">
                            <button mat-raised-button color="warn">
                                <mat-icon>{{action.icon}}</mat-icon>
                                <span>{{action.name}}</span>
                            </button>
                        </a>
                        <ng-container *ngIf="action.type && action.type == 'event'">
                            <button mat-button *ngIf="action?.btnType == 'box-button'"
                                (click)="emitEvent(action.actionName, element)"
                                [color]="action.color"
                                [ngClass]="{
                                    'mat-mdc-button mat-mdc-raised-button mat-raised-button': action?.style === 'raised',
                                    'mdc-button--outlined mat-mdc-outlined-button mat-stroked-button': action?.style === 'stroked',
                                    'mat-mdc-unelevated-button mat-unelevated-button': action?.style === 'flat'
                                  }"
                                [disabled]="action?.disabled ?? false"
                                matTooltip="{{action?.title ?? ''}}"
                                >
                                <mat-icon>{{action.icon}}</mat-icon>
                                <span>{{action?.btnText}}</span>
                            </button>
                            <button *ngIf="!action?.btnType"
                                (click)="emitEvent(action.actionName, element)"
                                [color]="action.color"
                                [ngClass]="action.class"
                                [disabled]="action?.disabled ?? false"
                                matTooltip="{{action?.title ?? ''}}"
                                mat-icon-button>
                                <mat-icon>{{action.icon}}</mat-icon>
                                <span>{{action?.btnText}}</span>
                            </button>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div *ngIf="column.name == 'actions' && (element.actions ?? column.actions).length > 0">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <ng-container *ngFor="let action of (element.actions ?? column.actions)">
                            <a class="row-action type" [ngClass]="action.class" *ngIf="!action.type" [routerLink]="[action.path+element[action.param]]">
                                <button mat-menu-item>
                                    <mat-icon [ngClass]="action.iconClass">{{action.icon}}</mat-icon>
                                    <span>{{action.name ?? action.btnText}}</span>
                                </button>
                            </a>
                            <button [ngClass]="action.class" *ngIf="action.type && action.type == 'goto-action'"
                                    (click)="this[action.callback](element)" mat-menu-item color="primary">
                                <mat-icon [ngClass]="action.iconClass">{{action.icon}}</mat-icon>
                                <span>{{action.name ?? action.btnText}}</span>
                            </button>
                            <button [ngClass]="action.class" *ngIf="action.type && action.type == 'event'"
                                    (click)="emitEvent(action.actionName, element)" mat-menu-item>
                                <mat-icon [ngClass]="action.iconClass">{{action.icon}}</mat-icon>
                                <span>{{action.name ?? action.btnText}}</span>
                            </button>
                            <button [ngClass]="action.class" *ngIf="action.type == 'function_call'" mat-menu-item (click)="action.callback(element)">
                                <mat-icon [ngClass]="action.iconClass">{{action.icon}}</mat-icon>
                                <span>{{action.name ?? action.btnText}}</span>
                            </button>
                        </ng-container>
                    </mat-menu>
                </div>
            </td>
        </ng-container>
        <!--
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
            </button>
            </td>
        </ng-container> -->

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container *ngIf="isExpandable" matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail"
            [@detailExpand]="(element == expandedElement) || isExpandable === true ? 'expanded' : 'collapsed'">
            <table class="customer-overview">
                <tr>
                    <th class="lastname">Prezime</th>
                    <th class="name">Ime</th>
                    <th class="sex">Pol</th>
                    <th>Br. telefona</th>
                    <th class="email">Email</th>
                    <th class="jmbg">JMBG</th>
                    <th class="passport">Broj pasoša</th>
                    <th class="address">Adresa</th>
                    <th class="entry-city">Mesto ulaska</th>
                    <th class="bus-plane-no">Broj busa/aviona</th>
                    <th class="seat-no">Broj sedišta</th>
                    <th class="room-no">Broj sobe</th>
                    <th class="note">Osig.</th>
                    <th class="note">Napomena</th>
                </tr>
                <tr *ngFor="let customer of element.customerList" class="customer-detail-box">
                    <td>{{ customer.customer_lastname ?? ''  }}</td>
                    <td>{{ customer.customer_name ?? ''  }}</td>
                    <td>{{customer.sex ?? ''  }}</td>
                    <td>{{ customer.customer_phone ?? ''  }}</td>
                    <td>{{ customer.customer_email ?? ''  }}</td>
                    <td>{{ customer.personal_id ?? ''  }}</td>
                    <td>{{ customer.customer_passport_no ?? ''  }}</td>
                    <td>{{ customer.customer_address || customer.customer_city ? customer.customer_address +', '+ customer.customer_city : ''  }}</td>
                    <td>{{ customer?.entryCity?.name ?? ''  }}</td>
                    <td>{{ customer.sett_transport_no ?? ''  }}</td>
                    <td>{{ customer.sett_seat_no ?? ''  }}</td>
                    <td>{{ customer.sett_room_no ?? ''  }}</td>
                    <td>{{ customer.sett_insurance == 1 ? 'DA' : 'NE'  }}</td>
                    <td>{{ customer.sett_notice ?? ''  }}</td>
                </tr>
            </table>
        </div>
        </td>
    </ng-container>

        <tr mat-header-row *matHeaderRowDef="isExpandable ? displayedColumns : displayedColumns;sticky: false"></tr>
        <tr mat-row *matRowDef="let element; columns: (isExpandable ? displayedColumns : displayedColumns);let rowIdx = dateIndex"
            class="example-element-row"
            [ngClass]="element.ltRowClass ?? ''"
            [class.example-expanded-row]="isExpandable === true"
            (click)="onExpandIconClick(element)">
        </tr>
        <ng-container *ngIf="isExpandable">
            <tr  mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </ng-container>
        <ng-container *ngIf="footerTotals">
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </ng-container>
    </table>
</div>