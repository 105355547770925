<h2 mat-dialog-title>{{title}}</h2>
<ng-container *ngIf="!isLoader; else loaderTemp">
  <mat-dialog-content class="mat-typography">
    <p>{{text}}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [ngClass]="confirmClass" mat-button [mat-dialog-close]="true" >{{confirmBtn}}</button>
    <button [ngClass]="cancelClass" mat-button [mat-dialog-close]="false" cdkFocusInitial>{{cancelBtn}}</button>
  </mat-dialog-actions>
</ng-container>
<ng-template #loaderTemp>
  <mat-dialog-content class="mat-typography">
    <mat-spinner></mat-spinner>
  </mat-dialog-content>
</ng-template>
