<mat-toolbar color="warn">    
    <mat-toolbar-row>
        <span>Kartica - Nova transakcija</span>
        <span class="example-spacer"></span>
       
    </mat-toolbar-row>
</mat-toolbar>

<mat-card-content *ngIf="loaded" >
    <mat-card id="file-upload-card">
        <dynamic-form
        [config]="config"
        (submit)="onSubmit($event)"> 
        </dynamic-form>
    
    </mat-card>  
</mat-card-content>