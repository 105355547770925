import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { AbstractInputComponent } from '../abstract-input.component';
import { FormEventService } from '../../form-event.service';

@Component({
    selector: 'form-radio',
    //styleUrls: ['form-select.component.scss'],
    template: `

  <ng-container [formGroup]="group">
    <label class="radio-label">{{config.label}}</label>
    <mat-radio-group [formControlName]="config.name" color="primary" (change)="onChange($event)" [(value)]="config.value" aria-label="Odaberite opciju">
        <mat-radio-button *ngFor="let option of config.options" [value]="option.id">{{ option.name }}</mat-radio-button>
        </mat-radio-group>
    <mat-hint *ngIf="config.hint">{{ config.hint }}</mat-hint>
    <ng-container *ngFor="let validation of config.validation" ngProjectAs="mat-error">
        <mat-error *ngIf="group.controls[config['name']].touched && group.controls[config['name']].hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
  </ng-container>
  `
})
export class FormRadioComponent extends AbstractInputComponent implements Field {
    config: FieldConfig;
    group: UntypedFormGroup;
    constructor(private formEventService: FormEventService) {
        super();
    }

    onChange(event) {
        if (this.config.eventMessages?.onChange != undefined) {
            this.formEventService.eventEmitter$.next({
                message: this.config.eventMessages?.onChange ?? null,
                el: this,
                value: event.value
            });
        }
    }
}