<style>
   ::ng-deep .user-message, ::ng-deep .note{
        max-width: 300px;
        min-width: 300px;
        white-space: initial;
    }
    
    ::ng-deep .referer{
        max-width: 300px;
        min-width: 300px;
        word-break: break-all;
        white-space: initial;
    }
    .fab-container {
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 100;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

            > div {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                margin-bottom: 5px;

                button {
                margin-bottom: 17px;
                }
            }
        }

        .fab-toggler {
        float: right;
        z-index: 100;
        }
</style>
<mat-progress-bar *ngIf="isSearching" mode="indeterminate"></mat-progress-bar>
<app-lt-filter *ngIf="filterConfig.length > 0" [filterConfig]="filterConfig" [cssClass]="'single-form'" (filterChanged)="onFilterChanged($event)"></app-lt-filter>
<app-lt-table
    [options]="tableOptions" 
    [columns]="columns"
    [dataSource]="dataSource"
    (tableEvent)="onTableEvent($event)">
</app-lt-table>
<app-lt-pagination [totalCount]="totalRowsCount" [pageSize]="inquiryPageSize" (paginationEvent)="paginationChanged($event)"></app-lt-pagination>
<div class="fab-container">
    <span>
        <button mat-fab class="fab-toggler" (click)="openNewInquiryDialog()" color="warn">
            <i class="material-icons">add</i>
        </button>
    </span>
</div>