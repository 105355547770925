<mat-card class="example-card">
    <mat-toolbar class="no-padding no-margin">
        <button mat-mini-fab color="primary"><mat-icon>receipt_long</mat-icon></button>&nbsp;Fiskalni računi
        <span class="example-spacer"></span>
        <div *ngIf="activeTab === 'final'">
            <button *ngIf="referenceItemId" mat-stroked-button color="primary" aria-label="Generiši finalni račun" (click)="openFinalFiscalDialog()"><mat-icon>receipt_long</mat-icon>Generiši finalni račun</button>
        </div>
        <div *ngIf="activeTab === 'advance'">
            <button *ngIf="referenceItemId" mat-stroked-button color="primary" aria-label="Generiši avansni račun" (click)="openAdvanceFiscalDialog({type: 'start'})"><mat-icon>receipt_long</mat-icon>Generiši avansni račun</button>
        </div>
        <ng-container *ngIf="activeTab === 'manual'">
            <button *ngIf="referenceItemId && activeTab === 'manual'" mat-stroked-button color="primary" aria-label="Dodaj fiskalni račun" (click)="openDialogFiscal()"><mat-icon>add</mat-icon>Unesi ručno fiskal</button>
        </ng-container>
        <h4 *ngIf="!referenceItemId">Dodavanje moguće nakon što se ugovor sačuva i doda u CIS</h4>
        &nbsp;
        
    </mat-toolbar>
    <mat-card-content>
        <div class="tab-menu-header">
            <button mat-button [class.active] = "activeTab === 'final'" (click)="setActiveTab('final')">Finalni ({{finalReceiptsData.length}})</button>
            <button mat-button [class.active] = "activeTab === 'advance'" (click)="setActiveTab('advance')">Avansni ({{advanceReceiptsData.length}})</button>
            <button mat-button [class.active] = "activeTab === 'manual'" (click)="setActiveTab('manual')">Ručno uneti ({{manualReceiptsData.length}})</button>
        </div>
        <div>
            <div *ngIf="activeTab === 'final' && referenceItemId">
                <app-lt-table
                    [columns]="finalColumns"
                    [dataSource]="finalReceiptsData"
                    (tableEvent)="onTableEvent($event)"></app-lt-table>
            </div>

            <app-lt-table *ngIf="activeTab === 'advance'  && referenceItemId"
                [columns]="advanceColumns"
                [dataSource]="advanceReceiptsData"
                (tableEvent)="onTableEvent($event)"></app-lt-table>
            <ng-container *ngIf="activeTab === 'manual'  && referenceItemId">
                <app-lt-table 
                [columns]="manualColumns"
                [dataSource]="manualReceiptsData"
                (tableEvent)="onTableEvent($event)"></app-lt-table>
            </ng-container>
        </div>
       
    </mat-card-content>
</mat-card>