import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {prepareFormGroup} from '../../shared/helpers/form';
import { FieldConfig } from 'src/app/components/dynamic-form-master/models/field-config.interface';
import { ResponseMessageService } from 'src/app/shared/services/response-message.service';
import { TransactionService } from 'src/app/transaction-new/transaction.service';
import { FiscalInvoiceType } from './fiscal-invoice-type';

export interface FiscalRequest {
  invoiceType: number;
  transactionType: number;
  payment: Array<{amount: number, paymentType: number}>;
  items: Array<{name: string, quantity: number, unitPrice: number, totalAmount: number, labels: Array<string>}>;
}

@Component({
  selector: 'app-fiscal-final-generate',
  styleUrls: ['./fiscal-final-generate.component.scss'],
  templateUrl: './fiscal-final-generate.component.html'
})
export class FiscalFinalGenerateComponent implements OnInit {
  fiscalReceiptsConfig = [];
  fiscalForms = new UntypedFormArray([]);
  paymentTypes = new UntypedFormGroup({
    type: new UntypedFormControl([1]),
  }); //kes
  paymentAmounts = new UntypedFormGroup({})

  paymentTypeOptions = [
    {value: 1, name: 'Gotovina'},
    {value: 2, name: 'Kartica'},
    {value: 3, name: 'Ček'},
    {value: 4, name: 'Virman'},
    {value: 5, name: 'Vaučer'},
    {value: 0, name: 'Ostalo'},
  ];
  paymentOptionsMappings = [];

  selectedOptions = {};
  changingItemsDisabled = false;
  totalFiscalProductsValue = 0;
  totalPayedIn = 0;

  constructor(
    private matDialogRef: MatDialogRef<FiscalFinalGenerateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      name: string;
      quantity: string;
      unitPrice: number;
      transactionType: number;
      paymentType: Array<{rawData: {type: string, amount: number}}>;
    },
    private fb: UntypedFormBuilder,
    private respMsg: ResponseMessageService,
    private transactionService: TransactionService
  ) {
  }

  ngOnInit(): void {
    this.transactionService.getAccountTypes().subscribe(account => this.paymentOptionsMappings = account);
    this.paymentTypeOptions.forEach(option => {
      const moneyType = option.value;
      const moneyTypesInternal = this.paymentOptionsMappings.filter(el => el.fiscalId == moneyType);
      let checked = false;
      //do not preselect for advanced
      moneyTypesInternal.forEach(internal => {
        const ltMoneyId = internal.id;
        checked = checked || (this.data?.paymentType && this.data.paymentType.find( el => parseInt(el.rawData.type) == ltMoneyId) ? true : false);
      });
      this.selectedOptions[moneyType] = {
        checked:  checked,
        name: option.name
      };
    });
    
    this.setFiscalReceiptConfig(this.data);
    this.setPaymentTypeAmounts();
  }

  paymentAmountKeys() {
    return Object.keys(this.paymentAmounts.controls);
  }
  
  paymentOptionChanged(paymentType) {
    const isSelected = this.selectedOptions[paymentType]['checked'];
    if (isSelected) {
        this.paymentAmounts.addControl(paymentType, this.fb.control(0));
    } else {
      delete this.paymentAmounts.controls[paymentType];
    }
  }

  setPaymentTypeAmounts() {
    const paymentAmounts = this.paymentAmounts as UntypedFormGroup;
    Object.keys(this.paymentTypeOptions).forEach(key => {
      const type = this.paymentTypeOptions[key].value;
      if (this.selectedOptions[type]['checked'] == true) {
        const moneyTypesInternal = this.paymentOptionsMappings.filter(el => el.fiscalId == type);
        let amount = 0;
        moneyTypesInternal.forEach(moneyTypeInternal => {
          const info = this.data.paymentType.find( el => parseInt(el.rawData.type) == parseInt(moneyTypeInternal.id));
          amount += info && info.rawData.amount ? info.rawData.amount : 0;
        });
        paymentAmounts.addControl(type, this.fb.control(amount));
      }
    });
  }

  setFiscalReceiptConfig(values: {
    name: string,
    quantity: string,
    unitPrice: number,
    labels?: Array<string> | null,
  
  } | null = null, disabled: boolean = false) {
    
    const config: FieldConfig[] = [
      {
        type: 'input',
        label: 'Opis usluge',
        name: 'name',
        placeholder: 'Opis usluge',
        validation: [Validators.required],
        value: values?.name || '',
        disabled: disabled,
        col:4
      },
      {
        type: 'input',
        label: 'Količina',
        name: 'quantity',
        placeholder: 'Količina',
        validation: [Validators.required],
        value: values?.quantity || 1,
        disabled: disabled,
        col:2
      },
      {
        type: 'input',
        label: 'Cena',
        name: 'unitPrice',
        placeholder: 'Cena',
        validation: [Validators.required],
        value: values?.unitPrice || '',
        disabled: disabled,
        col:2
      },
      {
        type: 'select',
        label: 'PDV kategorija',
        name: 'labels',
        multiple: true,
        validation: [Validators.required],
        options: [
          { id: 'А' , name: 'А'},
          { id: 'F' , name: 'F'}
        ],
        placeholder: 'PDV kategorija',
        value: values?.labels || null,
        disabled: disabled,
        col: 3
      },
    ];

    this.fiscalReceiptsConfig.push(config);
    this.fiscalForms.push(prepareFormGroup(config));
  }
  
  getFiscalForm(index) {
    return this.fiscalForms.at(index) as UntypedFormGroup;
  }
  addFiscal() {
    this.setFiscalReceiptConfig();
  }
  deleteFiscal(index) {
    this.fiscalForms.removeAt(index);
    this.fiscalReceiptsConfig.splice(index,1);
  }

  closeDialog() {

    let invalid = false;
    let items  = [];
    let totalServices = 0;
    for (let form of this.fiscalForms.controls) {
      if(form.invalid) {
        invalid = true;
        form.markAllAsTouched();
      }
      let properties = form.getRawValue();
        properties.totalAmount = properties.quantity * properties.unitPrice;
        totalServices += properties.totalAmount;
        items.push(properties);
      }
    if (invalid) {
      this.respMsg.showError('Ispunite / selektujte sva obavezna polja');
      return;
    }
    const amountsPerMoneyType =this.paymentAmounts.getRawValue();
    const payments = [];
    let totalPayments = 0;
    Object.keys(amountsPerMoneyType).forEach(type => {
      const amount = parseFloat(amountsPerMoneyType[type]);
      payments.push({
        amount: amount,
        paymentType: type  
      });
      totalPayments+= amount;
    });
    
    //todo check payment is it more than total, same as total left,
    // is tax amount same as total payed in atm
    
    let fiscalData: FiscalRequest = {
      invoiceType: FiscalInvoiceType.NORMAL,
      transactionType:0,
      payment: payments,
      items,
    };
    this.matDialogRef.close(fiscalData);
  }

}
