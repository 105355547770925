import { Component, Inject, Optional, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ContractService } from "../contract.service";
import { DynamicFormComponent } from "src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component";
import { ResponseMessageService } from "src/app/shared/services/response-message.service";


@Component({
    selector: 'app-cis-storno-dialog',
    template: `
            <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loading"></mat-progress-bar>

    <h3>

  Storniranje CIS ugovora #{{cisId}}
    </h3>
  <dynamic-form
        [config]="config"
        #form="dynamicForm">
        </dynamic-form>
        <br>
         <button class="green-btn" mat-button [disabled]="loading" (click)="doCisContractStorno($event)">
            Storniraj
        </button>
        &nbsp;
        <button class="" [disabled]="loading" mat-button [mat-dialog-close]="true">
            Nazad
        </button>
  `
})
export class CisStornoDialogComponent {
    cisId = null;
    @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
    loading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {cisId: string},
        @Optional() public dialogRef: MatDialogRef<CisStornoDialogComponent>,
        private contractService: ContractService,
        private respMessageService: ResponseMessageService,
    ) {
        this.cisId = data.cisId;
    }

    config = [
        {
            type: 'select',
            label: 'Razlog storniranja',
            placeholder: 'Razlog storniranja',
            name: 'reason',
            options:[
                {
                    name: "Odaberi opciju",
                    id: null
                },
                {
                    name: "Na zahtev agencije",
                    id: 1
                },
                {
                    name: "Na zahtev putnika",
                    id: 2
                }
            ]
        },
        {
            type: 'checkbox',
            label: 'Preskoči storniranje u CIS-u (već storniran u CIS-u)',
            name: 'skipCis',
            value: false,
            col: 2
        },
    ];

    doCisContractStorno(event) {
        this.loading = true;
        const reasonId = this.form.form.controls['reason'].value;
        if (!reasonId) {
            this.respMessageService.showError('Odaberite razlog storniranja');
            this.loading = false;
            return;
        }
        let skipCis = this.form.form.controls['skipCis'].value;
        if (skipCis === true) {
            skipCis = 1;
        } else {
            skipCis = 0;
        }

        this.contractService.stornoCisContract(this.cisId, reasonId, skipCis).subscribe(
            response => { 
                if(response['success'] === true) {    
                    this.dialogRef.close({event:'success', data: response['data']});
                } else {
                    this.respMessageService.showError(null);
                }
            },
            err => { 
                this.respMessageService.showError(err?.error?.message);
            }
        ).add(() => {
            this.loading = false;
        });
    }

    closeDialog(event) {
        this.dialogRef.close({});
    }

}