export const INQUIRY_STATUSES = [
    {
      id: '10',
      name: 'Neodgovoreno',
      description: 'Svima kojima nije odgovoreno'
    },
    {
      id: '20',
      name: 'U obradi',
      description: 'kada ga neko preuzme da odgovori na njega'
    },
    {
      id: '30',
      name: 'Komunikacija u toku',
      description: 'kada je neko preuzeo da odgovara na upit'
    },
    {
      id: '40',
      name: 'Rezervacija',
      desciption: 'Poslate instrukcije (oni koji su potvrdili da će da se prijave i kojima smo poslali instrukcije A prijavu na mejl)'
    },
    {
      id: '45',
      name: 'Odustali',
      description: 'Odustali'
    },
    {
      id: '50',
      name: 'Ugovor kreiran',
      description: '(oni koji su se prijavili)'
    },
];

export const PRERESERVATION_OPTIONS = [
  {
      id: 10,
      name: 'NE'
  },
  {
      id: 20,
      name: 'DA - ruming'
  },
  {
      id: 30,
      name: 'DA - viber'
  },
  {
      id: 40,
      name: 'DA - mail'
  },
  {
      id: 50,
      name: 'DA - poziv'
  },
];