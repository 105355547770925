import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { CisService } from 'src/app/cis/cis.service';
import { GlobalService } from 'src/app/model/global.service';
import { CommonDialogComponent } from 'src/app/shared/dialog/common-dialog.component';
import { GeneralDataService } from 'src/app/shared/services/general-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-authorised-layout',
  templateUrl: './authorised-layout.component.html',
  styleUrls: ['./authorised-layout.component.scss']
})
export class AuthorisedLayoutComponent implements OnInit {
  submenuOpenIcon: string = 'keyboard_arrow_down';
  submenuClosedIcon: string = 'keyboard_arrow_right';
  activeInquiryCount: number;
  activeInquiryCountSecondAgency: number;
  unreadCisNotifications = '';
  subs : Subscription[] = [];

  constructor(
    private jwtHelper: JwtHelperService,
    private router: Router,
    private general: GeneralDataService,
    public dialog: MatDialog,
    private cisService: CisService,
    //private eventService: FormEventService
  ) { }

  ngOnInit() {
    const token = GlobalService.getToken();
    if (token) {
        if(this.jwtHelper.isTokenExpired(token)){
            localStorage.removeItem(environment.tokenName);
            this.router.navigate(['/login']);
        }
        // logged in so return true
    } else {
      this.router.navigate(['/login']);
    }
    let userData = this.jwtHelper.decodeToken(token);

    if (!userData) {
      this.router.navigate(['/login']);
      return;
    }

    this.fullname = userData.data.fullname;
    this.company = userData.data.company;
    this.agencyId = userData.data.agId ?? null;
    if (this.agencyId == 3 || this.agencyId == 4) {
      this.isLider = true;
    }
    this.logo = userData.data.logo;
    this.subs.push(this.general.emitter$.subscribe((resp: any) => {
      this.activeInquiryCount = resp.data.active_inquiry_count;
      this.activeInquiryCountSecondAgency = resp.data.active_inquiry_count_2 ?? 0;
      if (!resp.data.hasCurrency) {
        const isCurrSet = localStorage.getItem('is_curr_set');
        if (isCurrSet !== '1') {
          this.handleCurrencyDialog();
        }
      }
    }));
    this.unreadCisNotifications = localStorage.getItem('cis_notif_count');
    this.subs.push(this.cisService.emitter$.subscribe((resp: any) => {
      this.unreadCisNotifications = resp.data?.count ?? localStorage.getItem('cis_notif_count');
    }));
  }

  isMenuOpen = true;
  contentMargin = 240;
  title = "Aurora";
  fullname = '';
  company = '';
  agencyId = null;
  isLider = false;
  logo = '';

  //submenu control
  transactionSubmenuVisible = true;
  bankTransactionSubmenuVisible = false;
  contractsSubmenuVisible = false;
  transactionNewSubmenuVisible = false;
  oldStuff = false;
  settings = false;
  settingsSubmenuVisible = false;


  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;

    if (!this.isMenuOpen) {
      this.contentMargin = 0;
    } else {
      this.contentMargin = 240;
    }
  }

  handleCurrencyDialog() {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '450px',
      data: {
        title: 'Kurs EUR nedostaje',
        text: 'Kurs EUR za današnji datum nije unesen.',
        confirmBtn: 'Dodaj kurs',
        cancelBtn: 'Otkaži',
        confirmClass: 'green-btn',
        cancelClass: 'red-btn',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        localStorage.setItem('is_curr_set', '1');
      }

      if (result == true) {
        this.router.navigate(['/exchange-rates']);
      }
    });
  }
  /*
  scroll(e) {
    this.eventService.eventEmitter$.next({
      message : 'content-scrolled',
      data: e
    });
  }
*/
  toggleSubmenu(submenuName) {
    switch (submenuName) {
      case 'oldStuff':
        this.oldStuff = !this.oldStuff;
        break;
      case 'transaction-new':
        this.transactionNewSubmenuVisible = !this.transactionNewSubmenuVisible;
        break;
      case 'bank-card-transaction':
        this.bankTransactionSubmenuVisible = !this.bankTransactionSubmenuVisible;
        break;
      case 'contracts':
        this.contractsSubmenuVisible = !this.contractsSubmenuVisible;
        break;

      case 'settings':
        this.settings = !this.settings;
        this.settingsSubmenuVisible = !this.settingsSubmenuVisible;
        break;
    }
  }
  goToNotifications() {
    this.router.navigate(['/cis/notifications']);
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      if (!sub.closed) {
        sub.unsubscribe();
      }
    }
  }
}

