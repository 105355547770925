import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Validators} from '@angular/forms';
import { DynamicFormComponent } from 'src/app/components/dynamic-form-master/containers/dynamic-form/dynamic-form.component';
import { FiscalInvoiceType } from './fiscal-invoice-type';

@Component({
  selector: 'app-fiscal-receipts-edit',
  templateUrl: './fiscal-receipts-edit.component.html',
  styleUrls: ['./fiscal-receipts-edit.component.css']
})
export class FiscalReceiptsEditComponent implements OnInit {

  fiscalReceiptsConfig = [];
  @ViewChild(DynamicFormComponent) fiscalReceiptsForm: DynamicFormComponent;

  constructor(
    private matDialogRef: MatDialogRef<FiscalReceiptsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      number: string | null;
      date: string | null;
      amount: number;
      id?: number;
    }) {
  }

  ngOnInit(): void {
    this.setFiscalReceiptConfig(this.data);
  }

  setFiscalReceiptConfig(values: {
    number: string;
    date: string;
    amount: number;
    id?: number;
  } | null = null) {
    
    this.fiscalReceiptsConfig = [
      {
        type: 'hidden',
        name: 'id',
        value: values?.id || ''
      },
      {
        type: 'hidden',
        name: 'fiscal_type',
        value: FiscalInvoiceType.MANUAL
      },
      {
        type: 'input',
        label: 'Broj fiskala',
        name: 'number',
        placeholder: 'Broj fiskala',
        validation: [Validators.required],
        value: values?.number || ''
      },
      {
        type: 'datepicker',
        label: 'Datum fiskala',
        name: 'date',
        placeholder: 'Datum fiskala',
        validation: [Validators.required],
        value: values?.date || ''
      },
      {
        type: 'input',
        label: 'Iznos',
        name: 'amount',
        placeholder: 'Iznos',
        validation: [Validators.required],
        value: values?.amount || ''
      }
    ];
  }

  closeDialog() {
    if(this.fiscalReceiptsForm.form.invalid) {
      this.fiscalReceiptsForm.form.markAllAsTouched();
      return;
    }

    const formValue = this.fiscalReceiptsForm.form.getRawValue();
    this.matDialogRef.close(formValue);
  }

}
