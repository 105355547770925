<style>
    .inline-menu {
        display: flex;
    list-style: none;
    }
    .sticky{
        position: fixed;
        top: 70px;
        z-index: 999;
    }
    #organizer {
        margin-top: 70px;
    }
</style>
<ng-container *ngIf="dataLoaded">
    <mat-toolbar color="primary" class="sticky">         
        <mat-toolbar-row>
            <span *ngIf="true">{{title}}</span>
            <ul #stickyMenu class="inline-menu">
                <li><button (click)="scrollToSection('organizer')" mat-button>Organizator</button></li>
                <li><button (click)="scrollToSection('arrangement')" mat-button>Aranžman</button></li>
                <li><button (click)="scrollToSection('usluge-card')" mat-button>Cene</button></li>
                <li><button (click)="scrollToSection('passengers')" mat-button>Putnici</button></li>
                <li><button (click)="scrollToSection('insurance')" mat-button>Osiguranje</button></li>
                <li><button (click)="scrollToSection('finance')" mat-button>Uplate/Isplate</button></li>
                <li><button (click)="scrollToSection('confirmation')" mat-button>Potvrda</button></li>
                <li><button (click)="scrollToSection('fiscal')" mat-button>Fiskali</button></li>
            </ul>
            <span class="example-spacer"></span>

        </mat-toolbar-row>
    </mat-toolbar>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

    <mat-card id="organizer" class="example-card">
        <mat-card-title>
            <button mat-mini-fab color="primary"><mat-icon>home_work</mat-icon></button> Organizator
        </mat-card-title>
        <mat-card-content>
            <mat-form-field>
                <input  [formControl]="selectedOperator" type="text" placeholder="Organizator" aria-label="Organizator" matInput [matAutocomplete]="autoOperator">
                    <mat-autocomplete (optionSelected)="providerChanged()"  (selectionChange)="providerChanged()" autoActiveFirstOption #autoOperator="matAutocomplete" [displayWith]="displayOperator">
                        <mat-option *ngFor="let item of organizerList" [value]="item">
                        {{ item.name | titlecase}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <dynamic-form #organizerForm [config]="organizerConfig"></dynamic-form>
        </mat-card-content>
    </mat-card>

    <mat-card id="arrangement" class="example-card">
        <mat-card-title>
            <button mat-mini-fab color="primary"><mat-icon>work_outline</mat-icon></button> Aranžman
        </mat-card-title>
        <mat-card-content>
            <mat-form-field>
                <input  [formControl]="arrangementSearch" type="text" placeholder="Pronađi hotel/aranžman" aria-label="Pretraga Aranžmana/Hotela" matInput [matAutocomplete]="autoArangement">
                    <mat-autocomplete (optionSelected)="arrangementSelectedOldSearch()" autoActiveFirstOption panelWidth="auto" #autoArangement="matAutocomplete">
                        <mat-option class="template-option" *ngFor="let item of arrangementSearchOptions" [value]="item">
                            <span>{{item.arrangment_accomodation}} | {{item.arrangement_name}}</span>
                            <small *ngIf="item.date_from">Date: {{item.date_from}} - {{item.date_to}} | {{item.days_number}} dan(a) {{item.nights_number}} noć(i)</small>
                            <small *ngIf="item.date_from">Prijava/Odjava: {{item.check_in_date}} - {{item.check_out_date}}</small>
                            <small *ngIf="item.room_name || item.service">Soba/Usluga: {{item.room_name}} | {{item.service}}</small>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <dynamic-form #arrangementForm [config]="arrangementConfig"></dynamic-form>
        </mat-card-content>
    </mat-card>

    <mat-card id="usluge-card" class="card-item">
        <mat-toolbar class="no-padding">
        <span><button mat-mini-fab color="primary"><mat-icon>shopping_cart</mat-icon></button> Cene</span>
        <span class="example-spacer"></span>
        <button mat-stroked-button color="primary" (click)="showHideFeeForm()"><mat-icon>{{showAddFeeForm ? 'close' : 'add'}}</mat-icon>{{showAddFeeForm ? 'Zatvori' : 'Dodaj cenu'}}</button>
        </mat-toolbar>
        <mat-card-content>
            <dynamic-form #addFeeForm *ngIf = "showAddFeeForm" [config]="feeFormConfig" (submit)="addFee($event, true)"></dynamic-form>
           <app-lt-table
                [columns]="feeListingColumns"
                [footerTotals]="feeTotal"
                [dataSource]="feeTableDataSource"
                (tableEvent)="onFeeTableEvent($event)"
                ></app-lt-table>
        </mat-card-content>
    </mat-card>

    <!-- Putnici-->
    <mat-card id="passengers" class="card-item">
        <mat-toolbar class="no-padding">
            <div><button mat-mini-fab color="primary"><mat-icon>perm_identity</mat-icon></button> Putnici</div>
            <span class="example-spacer"></span>
            
        </mat-toolbar>
        <mat-card-content>
            <div class="client-form__wrapper">
                <div *ngFor="let formGroup of clientsForm.controls; let i = index">
                    <h4>Putnik #{{i+1}}</h4>
                    <div class="client-form__group">
                        <dynamic-form #customers [form]="getClientForm(i)" [config]="clientsFormConfig[i]"></dynamic-form>

                        <div class="client-form__actions">
                            <mat-icon matListAvatar >account_circle</mat-icon>
                            <div>#{{i}}</div>
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="deleteClient(i)">
                                    <mat-icon  mat-list-icon >delete</mat-icon>
                                    <span>Obriši</span>
                                </button>
                            </mat-menu>
                        </div>
                        <div class="clientDetails">
                            <b (click)="clientAditionalFormShowHide[i].visible = !clientAditionalFormShowHide[i].visible" class="showHideClientDetails">Dodatni podaci o putniku
                                <mat-icon >{{clientAditionalFormShowHide[i].visible ? 'arrow_drop_down' : 'arrow_right'}}</mat-icon>
                            </b>
                            <dynamic-form *ngIf="clientAditionalFormShowHide[i].visible" [form]="getClientFormDetail(i)" [config]="clientsAditionalFormConfig[i]"></dynamic-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-passenger-holder">
                <mat-form-field class="contact-search__item">
                    <mat-icon matPrefix>search</mat-icon>
                    <input name="kupac" 
                    matInput placeholder="Nađi postojećeg putnika" aria-label="Nađi postojećeg putnika" 
                    [matAutocomplete]="auto" [formControl]="selectedClient">
                    
                    <mat-autocomplete class="client-search" [panelWidth]="400" #auto="matAutocomplete" [displayWith]="displayClient" (optionSelected)="addClient($event.option)">
                    <mat-option  *ngFor="let item of clientSearchList" [value]="item">
                        <div>{{item.lastname + ' ' + item.name + ' | ' + item.city}}</div>
                        <small>Jmbg: {{item.personal_id}} Tel: {{item.phone}} Email: {{item.email}}</small>
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div>&nbsp;&nbsp;ili&nbsp;&nbsp;</div>
                <div>
                    <button mat-raised-button color="primary" class="add-passenger" (click)="addClient({value: {}})"><mat-icon>add</mat-icon>Dodaj novog putnika</button>
                </div>
            </div>
        </mat-card-content>         
    </mat-card>
    <!-- END  Putnici -->

    <mat-card id="insurance" class="example-card">
        <mat-toolbar class="no-padding no-margin">
            <button mat-mini-fab color="primary"><mat-icon>health_and_safety</mat-icon></button>&nbsp;Polise osiguranja
            <span class="example-spacer"></span>
            <span *ngIf="!id">Dodavanje moguće nakon što se ugovor sačuva</span>
            <a *ngIf="id" mat-stroked-button color="primary" aria-label="Dodaj osiguranje" [routerLink]="['/insurance/new']" [queryParams]="{precontract: incrementalNumber}" ><mat-icon>add</mat-icon>Dodaj osiguranje</a>
        </mat-toolbar>
        <mat-card-content class="insurance-holder">
            <div class="insurance-item" *ngFor="let insurance of insuranceData">
                <a [routerLink]="['/insurance/', insurance.id]">
                    <b>Osiguranje broj #{{insurance.incremental_number}} <span *ngIf="insurance.isCanceled" class="red-text">STORNIRANO</span></b>
                </a>
                <p *ngFor="let person of insurance.persons">
                    {{person.lastname}} {{person.name}} ({{person.personal_id}})
                </p>
                <p><b>Cena:</b> {{insurance.mainCurrencyOwe?.servicesTotal | currency:' ':'symbol':'2.2-4':'sr'}} {{mainCurrencyName}}</p>
                <p><b>Uplaćeno:</b></p>
                <p *ngFor="let oneCurr of insurance.multiCurrencyOwe">{{oneCurr.paidTotalCurr}}</p>
                <ul class="style-none">
                    <li *ngIf="insurance?.confirmed && insurance?.confirmed == 1"><mat-icon>task_alt</mat-icon><b>Urađeno u osig. kući</b></li>
                    <li *ngIf="insurance?.canceled_in_insurance_company && insurance?.canceled_in_insurance_company == 1" class="red-text"><mat-icon>cancel</mat-icon><b>Stornirano u osig. kući</b></li>
                </ul>
                <p [className]="insurance.mainCurrencyOwe?.amount <= OWE_LIMIT[mainCurrencyName] ? 'green-text' : 'red-text'"><b>DUG:</b> {{insurance.mainCurrencyOwe?.amount | currency:' ':'symbol':'2.2-4':'sr'}} {{mainCurrencyName}}</p>
                
                
            </div>
        </mat-card-content>
    </mat-card>
    
    <mat-card id="finance" class="card-item">
        <mat-toolbar class="no-padding">
        <span><button mat-mini-fab color="primary"><mat-icon>paid</mat-icon></button> Uplate/Isplate</span>
        <span class="example-spacer"></span>
        
        <span *ngIf="!id">Dodavanje moguće nakon što se ugovor sačuva</span>
        </mat-toolbar>
        <mat-card-content *ngIf="id">
            <mat-tab-group>
                <mat-tab label="Putnici finansije">
                    <mat-toolbar class="no-padding no-margin">
                        <span class="example-spacer"></span>
                        <button mat-stroked-button aria-label="Dodaj uplatu/isplatu" (click)="openDialog()" color="primary"><mat-icon>add</mat-icon>Dodaj uplatu/isplatu</button>
                    </mat-toolbar>
                    <app-lt-table
                        [columns]="transactionColumns"
                        [footerTotals]="transactionFooterTotal"
                        [dataSource]="transactionDataSource"></app-lt-table>
                        <div class="col-sm-12 col-md-4" *ngIf="transactionsTotalPerMoneyType.length">
                            <table class="total-by-acc">
                                <tr><th colspan="2">TOTAL UPLATE PO VRSTI NOVCA</th></tr>
                                <tr *ngFor="let oneMoneyType of transactionsTotalPerMoneyType">
                                    <td><b>{{oneMoneyType.type}}: </b></td>
                                    <td class="total">
                                        <span *ngFor="let oneCurrAmount of oneMoneyType.currencies">{{oneCurrAmount}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Ukupno:</b></td>                                     
                                    <td class="total"><span *ngFor="let item of payedInTotal"> {{item}}</span></td>
                                </tr>
                            </table>
                            <!--
                                <span>TOTAL PO VRSTI NOVCA:</span>
                                <h3 *ngFor="let oneMoneyType of transactionsTotalPerMoneyType">
                                    <b>{{oneMoneyType.type}}: </b> <span>{{oneMoneyType.amount}}</span>
                                </h3>
                                <h3>
                                    <span>Ukupno uplaćeno:</span>
                                    <span *ngFor="let item of payedInTotal"> {{item}}</span> 
                                </h3>
                            -->
                        </div>
                        <!--
                        <div class="col-sm-12 col-md-4" *ngIf="transactionsTotalPerMoneyType.length">
                            <table class="total-by-acc">
                                <tr>
                                    <td><b>Potraživanje:</b></td>
                                    <td class="total">
                                        <span *ngFor="let oneCurrency of financeStatus"> {{oneCurrency.servicesTotal}} </span> 
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Uplate:</b></td>                                     
                                    <td class="total">
                                        <span *ngFor="let oneCurrency of financeStatus"> {{oneCurrency.paidTotal}} </span> 
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Total: </strong></td>
                                    <td class="total"><span *ngFor="let oneCurrency of financeStatus" [className]="oneCurrency.status ? 'green-text' : 'red-text'" >{{oneCurrency.owe}}</span></td>
                                </tr>
                            </table>
                        </div>
                        -->
                        <div class="col-sm-12 col-md-4" >
                            <table class="total-by-acc">
                                <tr>
                                    <td><b>Ukupna cena: </b></td>
                                    <td class="total">
                                        <span *ngFor="let fee of feeTotal.total_formatted"> {{ fee.value | currency:' ':'symbol':'2.2-4':'sr' }} {{fee.currencyName}} </span> 
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Ukupno uplaćeno:</b></td>                                     
                                    <td class="total"><span *ngFor="let item of payedInTotal"> {{item}}</span></td>
                                </tr>
                                <tr>
                                    <td><b>Ukupan DUG u {{mainCurrencyName}}:</b></td>
                                    <td class="total">
                                        <span [className]="mainCurrencyOweTotal.originalAmount <=OWE_LIMIT[mainCurrencyName] ? 'green-text' : 'red-text'"> <h3>{{mainCurrencyOweTotal.amount}} ({{mainCurrencyOweTotal.oweInEur}}) {{mainCurrencyOweTotal.error}}</h3></span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <!--
                        <div class="finance-status-total">

                            <div class="finance-totals right">
                                <h3>
                                    <span>Cena aranžmana:</span>
                                    <span *ngFor="let fee of feeTotal.total_formatted"> {{ fee.value | currency:' ':'symbol':'2.2-4':'sr' }} {{fee.currencyName}} </span> 
                                </h3>
                                <h3>
                                    <span>Ukupno uplaćeno:</span>
                                    <span> {{mainCurrencyOweTotal.paidIn}} {{mainCurrencyOweTotal.error}}</span> 
                                </h3>
                                <h2><span>Dug total u {{mainCurrencyName}}:</span>
                                    <span [className]="mainCurrencyOweTotal.originalAmount <=OWE_LIMIT[mainCurrencyName] ? 'green-text' : 'red-text'"> {{mainCurrencyOweTotal.amount}} {{mainCurrencyOweTotal.error}}</span> 
                                </h2>
                            </div>
                        </div>
                        -->
                        <!--
                        <div class="lt-finance-status-box">  
                            <mat-card *ngFor="let oneCurrency of financeStatus">
                                <mat-card-header>
                                    <div mat-card-avatar><mat-icon>payments</mat-icon></div>
                                    <mat-card-title>
                                        Dug: <b [className]="oneCurrency.status ? 'green-text' : 'red-text'">{{oneCurrency.owe}} {{oneCurrency.currencyName}}</b>
                                    </mat-card-title>
                                    <mat-card-subtitle>Potraživanje: <b>{{oneCurrency.servicesTotal}} {{oneCurrency.currencyName}}</b></mat-card-subtitle>
                                    <mat-card-subtitle>Uplaćeno: <b>{{oneCurrency.paidTotal}} {{oneCurrency.currencyName}}</b></mat-card-subtitle>
                                </mat-card-header>
                            </mat-card>
                            <mat-card>
                                <mat-card-header>
                                    <div mat-card-avatar class="example-header-image"><mat-icon>account_balance_wallet</mat-icon></div>
                                    <mat-card-title>
                                        Dug total u {{mainCurrencyName}}:
                                        <b [className]="mainCurrencyOweTotal.amount <=50 ? 'green-text' : 'red-text'">
                                            {{mainCurrencyOweTotal.amount | currency:' ':'symbol':'2.2-4':'fr'}} {{mainCurrencyOweTotal.error}}
                                        </b>
                                    </mat-card-title>
                                    <mat-card-subtitle><b></b></mat-card-subtitle>
                                    <mat-card-subtitle><b></b></mat-card-subtitle>
                                </mat-card-header>
                            </mat-card>
                        </div> -->
                </mat-tab>
                <mat-tab label="Dobavljači finansije">
                    <mat-toolbar class="no-padding no-margin">
                        <span class="example-spacer"></span>
                        <button mat-stroked-button aria-label="Dodaj uplatu/isplatu" (click)="openDialog('supplier')" color="primary"><mat-icon>add</mat-icon>Dodaj uplatu/isplatu</button>
                    </mat-toolbar>
                    <app-lt-table
                        [columns]="supplierTransactionColumns"
                        [footerTotals]="supplierTransactionFooterTotal"
                        [dataSource]="supplierTransactionDataSource"></app-lt-table>
                    <div class="finance-status-total">
                        <div class="left">
                            <h3 class="finance-status" *ngFor="let oneCurrency of financeStatusSupplier">
                                Potraživanje: {{oneCurrency.servicesTotal}}
                                <br>
                                Uplate: {{oneCurrency.paidTotal}}
                                <br>
                                <strong>Total: </strong> <b [className]="oneCurrency.status ? 'green-text' : 'red-text'" >{{oneCurrency.owe}}</b>
                            </h3>
                        </div>
                        <div class="right">
                            <h2>Dug total u {{mainCurrencyName}}: 
                                <span [className]="supplierMainCurrencyOweTotal.amount <= OWE_LIMIT[mainCurrencyName] ? 'green-text' : 'red-text'"> {{supplierMainCurrencyOweTotal.amount | currency:' ':'symbol':'2.2-4':'sr'}} {{supplierMainCurrencyOweTotal.error}}</span> 
                            </h2>
                        </div>
                    </div>
                </mat-tab>
              </mat-tab-group>
           
        </mat-card-content>
    </mat-card>

    <!--
    <arrangment-service [customers]="clientList$"  [contractId]="id" ></arrangment-service>
    -->
   
    
    <mat-card id="confirmation">
        <mat-card-title>
            <button mat-mini-fab color="primary"><mat-icon>work_outline</mat-icon></button> Potvrda rezervacije - info
        </mat-card-title>
        <mat-card-content>
            <dynamic-form #reservationConfirmationForm [config]="reservationConfirmationConfig"></dynamic-form>
        </mat-card-content>
    </mat-card>
    <br>
    <app-fiscal-receipts-list  id="fiscal" 
        *ngIf="dataLoaded"
        [receipts]="fiscalReceipts"
        [referenceItemId]="id"
        [fiscalAmount]="totalPayedInMainCurrency"
        [fiscalServiceName]="getArrangementName()"
        [amountsPerMoneyType]="transactionsTotalPerMoneyType"
        [cisContractId]="cisId"
        ></app-fiscal-receipts-list>

    <mat-toolbar class="page-actions">
        <!-- @todo use constant url path-->
        <button [disabled]="!id || sendingAnnounce" (click)="announcementToOrganizerPDF()" mat-raised-button>
            <mat-icon>record_voice_over</mat-icon>
            <span>&nbsp;Pošalji najavu
            <img *ngIf="sendingAnnounce" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </span>
        </button>&nbsp;
        <button [disabled]="!id || sendingAnnounce" (click)="downloadAnnouncementToOrganizer()" mat-raised-button>
            <mat-icon>download</mat-icon>
            <span>&nbsp;Preuzmi najavu
            <img *ngIf="sendingAnnounce" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </span>
        </button>&nbsp;
        
        <button *ngIf="!cisId" [disabled]="!id || sendingToCis" (click)="sendToCis()" mat-raised-button>
            <mat-icon>upload_file</mat-icon>
            <span>&nbsp;Pošalji u CIS

            <img *ngIf="sendingToCis" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </span>
        </button>&nbsp;
        <span *ngIf="cisId" >
            CIS
            <span>#{{cisId}}</span>
        </span>
        <button *ngIf="cisId" [disabled]="!id || requestingGuarantee" (click)="getTravelGuaranteeFromCis()" mat-raised-button>
            <mat-icon>download_file</mat-icon>
            <span>&nbsp;Preuzmi garanciju

            <img *ngIf="requestingGuarantee" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </span>
        </button>
        <a *ngIf="id" [routerLink]="'/contract/print/'+id">
            <button mat-raised-button>
                <mat-icon>picture_as_pdf</mat-icon>
                <span>&nbsp;Ugovor</span>
            </button>
        </a>&nbsp;
        <button mat-raised-button (click)="sendContractToClient()">
            <mat-icon>email</mat-icon>
            <span>Pošalji ugovor</span>
        </button>&nbsp;
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button (click)="stornoCisContract()" [disabled]="!cisId" mat-menu-item>
                <mat-icon>cancel</mat-icon>
                <span>Storniraj CIS ugovor</span>
            </button>
        </mat-menu>
        <span class="example-spacer"></span>
        <mat-form-field class="contract-status">
            <mat-label>Status ugovora</mat-label>
            <mat-select [formControl]="contractStatus">
              <mat-option *ngFor="let status of contractStatusMap | keyvalue" value="{{status.key}}">{{status.value.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        <button [disabled]="isLoading" mat-raised-button (click)="save()" color="primary">
            <mat-icon>save</mat-icon> Sačuvaj promene
        </button>
        <!--
        <button *ngIf="contractsBookId" disabled mat-button color="primary">
            <mat-icon>check_circle_outline</mat-icon> Dodat u KEPTU
        </button>
        &nbsp;
        &nbsp;
        <button *ngIf="!contractsBookId" [disabled]="isLoading" mat-stroked-button (click)="finalizeContract()" color="primary">
            <mat-icon>done</mat-icon> Kreiraj Ugovor
        </button>
        -->
        
    </mat-toolbar>
</ng-container>