import { BrowserModule } from '@angular/platform-browser';
//import { AngularFileUploaderModule } from "angular-file-uploader";
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule,routingComponents } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {JWT_OPTIONS,JwtModule} from '@auth0/angular-jwt';
import {environment} from './../environments/environment';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './model/auth.guard';
import {AlertService} from './_services';
import { MassUploadComponent } from './mass-upload/mass-upload.component';
import { TransactionModule } from './transaction/transaction.module';
import { ContactModule } from './contact/contact.module';
import { ContractModule } from './contract/contract.module';
import { PrintComponent } from './_layout/print.component';
import { TokenInterceptor } from './_services/token.interceptor';
import { SharedModule } from './shared/shared.module';
import {OrganizerModule} from "./organizer/organizer.module";
import { InventoryModule } from './inventory/inventory.module';
import { HotelFilterModule } from './entity-filter/hotel-filter.module';
import { InquiryModule } from './inquiry/inquiry.module';


export function jwtOptionsFactory() { 
  return {
      tokenGetter: () => {
          return localStorage.getItem(environment.tokenName) || '';
      }
  };
}


@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    MassUploadComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    //AngularFileUploaderModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory,
      }
  }),
  TransactionModule,
  ContactModule,
  HotelFilterModule,
  ContractModule,
  OrganizerModule,
  InventoryModule,
  InquiryModule,
  SharedModule
  ],
  providers: [
    AuthGuard,
    AlertService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { } 
