import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResponseBody } from 'src/app/model/response-body';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class GeneralDataService {
    url =  environment.apiHost + '/lider/general';
    emitter$ = new Subject();
    static readonly CONTRACT_OWE_LIMIT_KEY = 'contract_owe_limit';
    static readonly INSURANCE_OWE_LIMIT_KEY = 'insurance_owe_limit';
    static readonly AUR_SETTINGS_KEY = 'aur_settings';
    
    constructor(protected http: HttpClient) {}
    get() {
        //@todo duplicated in inquiry list
        let params = {
            'inq_status' : '10,30,40',
            'inq_status_id' : '10'
        };
        this.http.get<ResponseBody<any[]>>(this.url + '/data', {params}).subscribe({
            next: (resp: any) => {
                this.emitter$.next({data: resp.data});
            }
        });
    }

    getSettings() {
        return this.http.get<ResponseBody<any[]>>(this.url + '/settings', {});
        
        /*.subscribe({
            next: (resp: any) => {
                this.emitter$.next({data: resp.data});
            },
            error: (err) => {
                alert('Došlo je do greške pri učitavanju inicijalnih podešavanja. molimo Vas ulogujte se ponovo.');
            }
        });
        */
    }
    
}