/* tslint:disable:prefer-const */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { ResponseMessageService } from '../shared/services/response-message.service';

@Injectable({ providedIn: 'root' })
export class CisService {
    emitter$ = new Subject();

    constructor(
        private http: HttpClient,
        private rspMsgService: ResponseMessageService
    ) {
        
    }

    url = environment.apiHost + '/lider/cis';

    fetchNotifications(): Observable<{success: boolean;data: any}> {
        let params = {};
        return this.http.get<any>(this.url + '/fetch-notifications', {params});
    }

    readNotification(params): Observable<{success: boolean;data: any}> {
        return this.http.post<any>(this.url + '/read-notification',  params);
    }

    getUnreadNotifications(skipIntervalCheck = false): any{
        
        const item: number = Number(localStorage.getItem('cis_notif_interval'));
        const notificationCount: number = Number(localStorage.getItem('cis_notif_count'));
        if (!skipIntervalCheck && (item && ((Date.now() - item)/1000 < 60))) {
            return false;
        }
        let params = {};
        this.http.get<any>(this.url + '/get-unread-notifications', {params}).subscribe(
            resp => {
                this.emitter$.next({data: resp.data});
                localStorage.setItem('cis_notif_interval', String(Date.now()));
                localStorage.setItem('cis_notif_count', String(resp?.data?.count));
            },
            error => {
                //this.rspMsgService.showError(error?.error?.message);
                localStorage.setItem('cis_notif_interval', String(Date.now()));
            }
        );
    }
}

