<mat-toolbar id="mainToolbar" color="primary">
    <mat-icon (click)="onToolbarMenuToggle()" id="toolbarMenu">subject
    </mat-icon>
    {{title}}
    <span class="toolbar-spacer"></span>
    <div *ngIf="isLider" class="cis-notifications" (click)="goToNotifications()">
        <mat-icon matTooltip="CIS obaveštenja/notifikacije">mark_chat_unread</mat-icon>
        <span [matBadge]="unreadCisNotifications" [matBadgeHidden]="'false'"
        matBadgeSize="medium" matBadgeOverlap="false" matBadgeColor="warn"></span>
    </div>

    <div class="user-menu">
        <div class="user-details">{{fullname}}<span>{{company ? ' - '+company : ''}}</span></div>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>person</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item>
                <mat-icon>account_circle</mat-icon>{{fullname}}<span>{{company ? ' - '+company : ''}}</span>
            </button>

            <a [routerLink]="['/logout']" mat-menu-item>
                <mat-icon>exit_to_app</mat-icon>
                <span>Izloguj se</span>
            </a>
        </mat-menu>
    </div>
    <!--<mat-icon
       class="toolbar-icon"
       matBadge="8"
       matBadgePosition="after"
       matBadgeColor="accent"
     >notifications</mat-icon>
    -->
    
</mat-toolbar>

<mat-sidenav-container id="sidenavContainer" fullscreen>
    <mat-sidenav mode="side" #sidenav id="sidenav" [class.menu-open]="isMenuOpen" [class.menu-close]="!isMenuOpen"
        opened>

        <mat-list id="menus">
            <mat-card>
                <mat-card-header class="cmp-logo">
                    <div *ngIf="logo" mat-card-avatar class="avatar">
                        <img [src]="logo" alt="">
                    </div>

                    <h2>{{company}}</h2>
                    <!--<mat-card-subtitle>Internal</mat-card-subtitle>-->
                </mat-card-header>
            </mat-card>

            <mat-list-item *ngIf="isLider">
                <a [routerLink]="['/inquiry']">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>forum</mat-icon>
                        <span [matBadge]="activeInquiryCount" [matBadgeHidden]="activeInquiryCount == 0"
                            matBadgeSize="medium" matBadgeOverlap="false" matBadgeColor="warn">Upiti</span>
                            <span [matBadge]="activeInquiryCountSecondAgency" [matBadgeHidden]="activeInquiryCountSecondAgency == 0"
                            matBadgeSize="medium" matBadgeOverlap="false" matBadgeColor="warn">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </button>
                </a>
            </mat-list-item>
            <mat-list-item *ngIf="isLider">
                <a [routerLink]="['/travel-application']">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>playlist_add_check_circle</mat-icon>
                        Rezervacije
                    </button>
                </a>
            </mat-list-item>
            <mat-list-item>
                <a (click)="toggleSubmenu('contracts')">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>description</mat-icon>
                        Ugovori
                        <mat-icon class="submenu-status-icon">{{contractsSubmenuVisible ? submenuOpenIcon :
                            submenuClosedIcon}}</mat-icon>
                    </button>
                </a>
            </mat-list-item>
            <mat-list [class.visible]="contractsSubmenuVisible" class="submenu">
                <mat-list-item>
                    <a [routerLink]="['/contract']">
                        <button class="menu-item" mat-flat-button>
                            <mat-icon>description</mat-icon>
                            Ugovori
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/passenger-list']">
                        <button class="menu-item" mat-flat-button>
                            <mat-icon>people</mat-icon>
                            Spisak putnika
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/book-of-contracts']">
                        <button class="menu-item" mat-flat-button>
                            <mat-icon>chrome_reader_mode</mat-icon>
                            Knjiga evidencije
                        </button>
                    </a>
                </mat-list-item>
            </mat-list>
            <mat-list-item>
                <a [routerLink]="['/insurance']">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>health_and_safety</mat-icon>
                        Polise osiguranja
                    </button>
                </a>
            </mat-list-item>
            <mat-list-item>
                <a (click)="toggleSubmenu('transaction-new')">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>swap_vert</mat-icon>
                        Nova blagajna
                        <mat-icon class="submenu-status-icon">{{transactionNewSubmenuVisible ? submenuOpenIcon :
                            submenuClosedIcon}}</mat-icon>
                    </button>
                </a>
            </mat-list-item>
            <mat-list [class.visible]="transactionNewSubmenuVisible" class="submenu">
                <mat-list-item>
                    <a [routerLink]="['/transaction-new']">
                        <button class="menu-item" mat-flat-button>
                            Danas
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/transaction-new/archive']">
                        <button class="menu-item" mat-flat-button>
                            Arhiva
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/installment-payment']">
                        <button class="menu-item" mat-flat-button>
                            Administrativna zabrana
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/transaction-new/overview']">
                        <button class="menu-item" mat-flat-button>
                            Izveštaji
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/exchange-rates']">
                        <button class="menu-item" mat-flat-button>
                            Kursna lista
                        </button>
                    </a>
                </mat-list-item>
            </mat-list>
            
            <mat-list-item>
                <a [routerLink]="['/organizer']">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>home_work</mat-icon>
                        Organizatori
                    </button>
                </a>
            </mat-list-item>
            <mat-list-item>
                <a [routerLink]="['/contact']">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>account_box</mat-icon>
                        Kontakti
                    </button>
                </a>
            </mat-list-item>
            <mat-list-item *ngIf="isLider">
                <a [routerLink]="['/hotel']">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>hotel</mat-icon>
                        Smeštaj
                    </button>
                </a>
            </mat-list-item>
            <mat-list-item *ngIf="isLider">
                <a (click)="toggleSubmenu('oldStuff')">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>swap_vert</mat-icon>
                        Staro
                        <mat-icon class="submenu-status-icon">{{oldStuff ? submenuOpenIcon :
                            submenuClosedIcon}}</mat-icon>
                    </button>
                </a>
            </mat-list-item>
            <mat-list [class.visible]="oldStuff" class="submenu">
                <mat-list-item>
                    <a [routerLink]="['/transaction/open']">
                        <button class="menu-item" mat-flat-button>
                            Transakcije danas
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/periods']">
                        <button class="menu-item" mat-flat-button>
                            Transakcije arhiva
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/bank-card-transaction']">
                        <button class="menu-item" mat-flat-button>
                            Kartica transakcije danas
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/bank-card-transaction/archive']">
                        <button class="menu-item" mat-flat-button>
                            Kartica transakcije arhiva
                        </button>
                    </a>
                </mat-list-item>
            </mat-list>
            <mat-list-item>
                <a (click)="toggleSubmenu('settings')">
                    <button class="menu-item" mat-flat-button>
                        <mat-icon>settings</mat-icon>
                        Podešavanja
                        <mat-icon class="submenu-status-icon">{{settingsSubmenuVisible ? submenuOpenIcon :
                            submenuClosedIcon}}</mat-icon>
                    </button>
                </a>
            </mat-list-item>
            <mat-list [class.visible]="settings" class="submenu">
                <mat-list-item>
                    <a [routerLink]="['/settings/cis-program-mapping']">
                        <button class="menu-item" mat-flat-button>
                            CIS mapiranje podataka
                        </button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a [routerLink]="['/settings/agency-settings']">
                        <button class="menu-item" mat-flat-button>
                            Podaci agencije
                        </button>
                    </a>
                </mat-list-item>
            </mat-list>
        </mat-list>
    </mat-sidenav>

    <mat-sidenav-content id="sidenavContent" [class.left-margin]="isMenuOpen" [ngStyle]="{ 'margin-left.px': contentMargin }">
        <app-page-content></app-page-content>
    </mat-sidenav-content>
</mat-sidenav-container>