import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class SettingsService {
    readonly SHOW_OWE_ON_PAYMENT_RECEIPT = 'show_owe_on_payment_recipt';
    url =  environment.apiHost + '/lider/settings';
    headers = {
        'enctype': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem(environment.tokenName)
    };
    constructor(protected http: HttpClient) {}

    get(key: string): Observable<any> {
        const headers = this.headers;
        const params = {
            key: key
        };
        return this.http.get<any>(this.url + '/get', {params, headers});
    }

    save(entity: any): Observable<any> {
        const headers = this.headers;
        return this.http.post<any>(this.url + '/set', entity, {headers});
    }
    
}