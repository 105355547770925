import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable()
export class FiscalReceiptService {
    readonly CANCELLED_CONTRACT = 4;
    constructor(private http: HttpClient) {
    }

    headers = {
        enctype: 'multipart/form-data',
    };
    url = environment.apiHost + '/lider/fiscal-receipt';

    findById(id: string): Observable<any> {
        const url = this.url + '/' + id;
        const params = {};
        const headers = this.headers;
        return this.http.get<any>(url, {params, headers});
    }

    generateFiscal(params) {
        const headers = this.headers;
        let url =  this.url + '/generate-fiscal';
        return this.http.post<any>(url, params, {headers});
    }

    generateAdvanceFiscal(params) {
        const headers = this.headers;
        let url =  this.url + '/generate-advance-fiscal';
        return this.http.post<any>(url, params, {headers});
    }

}

