import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MassUploadComponent } from './mass-upload/mass-upload.component';
import { TransactionEditComponent } from './transaction/transaction-edit/transaction-edit.component'; 
import { TransactionListComponent } from './transaction/transaction-list/transaction-list.component';
import { PageContentComponent } from './layout/page-content/page-content.component';
import { GuestTopNavComponent } from './layout/guest/guest-top-nav/guest-top-nav.component';
import { GuestFooterComponent } from './layout/guest/guest-footer/guest-footer.component';
import { GuestLayoutComponent } from './layout/guest/guest-layout/guest-layout.component';
import { AuthorisedTopNavComponent } from './layout/authorised/authorised-top-nav/authorised-top-nav.component';
import { AuthorisedSideNavComponent } from './layout/authorised/authorised-side-nav/authorised-side-nav.component';
import { AuthorisedLayoutComponent } from './layout/authorised/authorised-layout/authorised-layout.component';
import { AuthorisedSideNavTogglerComponent } from './layout/authorised/authorised-side-nav-toggler/authorised-side-nav-toggler.component';
import { FullComponent } from './_layout/full.component';
import { AuthGuard } from './model/auth.guard';
import { ContractComponent } from './contract/contract.component';
import { OpenTransactionComponent } from './transaction/open/open-transaction.component';
import { ReceiptComponent } from './transaction/print/receipt.component';
import { PrintComponent } from './_layout/print.component';
import { PeriodDetailsComponent } from './transaction/archive/period-details.component';
import { PeriodComponent } from './transaction/archive/period.component';
import { BankCardTransactionNewComponent } from 'src/app/features/bank-card-transaction/bank-card-transaction-new.component';
import { BankCardTransactionListComponent } from './features/bank-card-transaction/bank-card-transaction-list.component';
import { BankCardTransactionArchiveComponent } from './features/bank-card-transaction/bank-card-transaction-archive.component';
import { ContractEditNewComponent } from './contract/contract-edit/contract-edit-new.component';
import {BookOfContractsComponent} from './contract/book-of-contracts/book-of-contracts.component';
import { ContractPrintComponent } from './contract/print/contract-print.component';
import { PassengerListComponent } from './contract/passenger-list/passenger-list.component';
import { TravelApplicationEditComponent } from './contract/travel-application/travel-application-edit.component';
import { TravelApplicationListComponent } from './contract/travel-application/travel-application-list.comonent';

const routes: Routes = [
  { path: '', redirectTo: '/contract', pathMatch: 'full'},
  {
    path: 'login',
    component: FullComponent,
    children: [
      { path: '', component: LoginComponent},
    ]
  },
  {
    path: 'transaction/print',
    component: PrintComponent,
    children: [
      {path: 'receipt/:id', component : ReceiptComponent}
    ]

  },
  {
    path: 'contract/print/:id',
    component: FullComponent,
    children: [
      {path: '', component : ContractPrintComponent}
    ]
  },
  { path: 'transaction-new', loadChildren: () => import('./transaction-new/transaction.module').then(m => m.TransactionModule) },
  {
    path: '',
    component: AuthorisedLayoutComponent,
    children: [
      { path: 'mass-upload', component: MassUploadComponent },
      { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
      { path: 'organizer', loadChildren: () => import('./organizer/organizer.module').then(m => m.OrganizerModule) },
      { path: 'insurance', loadChildren: () => import('./insurance/insurance.module').then(m => m.InsuranceModule) },
      { path: 'inquiry', loadChildren: () => import('./inquiry/inquiry.module').then(m => m.InquiryModule) },
      { path: 'hotel', loadChildren: () => import('./hotel/hotel.module').then(m => m.HotelModule) },
      { path: 'hotel-filter', loadChildren: () => import('./entity-filter/hotel-filter.module').then(m => m.HotelFilterModule) },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
      { path: 'exchange-rates', loadChildren: () => import('./exchange-rates/exchange-rates.module').then(m => m.ExchangeRatesModule) },
      { path: 'installment-payment', loadChildren: () => import('./installment-payment/installment-payment.module').then(m => m.InstallmentPaymentModule) },
      { path: 'cis', loadChildren: () => import('./cis/cis.module').then(m => m.CisModule) },
      {
        path: 'transaction',
        component: TransactionListComponent
      },
      {
        path: 'transaction/open',
        component: OpenTransactionComponent
      },
      {
        path: 'transaction/:id',
        component: TransactionEditComponent
      },
      {
        path: 'bank-card-transaction',
        component: BankCardTransactionListComponent
      },
      {
        path: 'bank-card-transaction/archive/:id',
        component: BankCardTransactionListComponent
      },
      {
        path: 'bank-card-transaction/archive',
        component: BankCardTransactionArchiveComponent
      },
      {
        path: 'bank-card-transaction/:id',
        component: BankCardTransactionNewComponent
      },
      {
        path: 'periods',
        component: PeriodComponent
      },
      {
        path: 'periods/:id',
        component: PeriodDetailsComponent
      },
      {
        path: 'contract',
        component: ContractComponent
      },
      {
        path: 'contract/:id',
        component: ContractEditNewComponent
      },
      {
        path: 'passenger-list',
        component: PassengerListComponent
      },
      {
        path: 'book-of-contracts',
        component: BookOfContractsComponent
      },
      {
        path: 'travel-application/:id',
        component: TravelApplicationEditComponent
      },
      {
        path: 'travel-application',
        component: TravelApplicationListComponent
      },
    ],
    canActivate: [AuthGuard]
  },
  { path: 'logout', component: LogoutComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
export const routingComponents = [
  LoginComponent, 
  GuestLayoutComponent, 
  AuthorisedLayoutComponent, 
  GuestTopNavComponent,
  PageContentComponent,
  GuestFooterComponent,
  AuthorisedTopNavComponent, 
  AuthorisedSideNavComponent,
  AuthorisedSideNavTogglerComponent,
  FullComponent,
  LogoutComponent,
  MassUploadComponent,
  ]