import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GeneralDataService } from './shared/services/general-data.service';
import { GlobalService } from './model/global.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { CisService } from './cis/cis.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Aurora';
  subscriptions = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private general: GeneralDataService,
    private jwtHelper: JwtHelperService,
    private cisService: CisService
  ) {
  }
  isMenuOpen = false;
  contentMargin = 240;

  task: string[] = [
    'Clearning out my closet', 'Take out trash bins', 'Wash car', 'Tank up the motorcycles', 'Go for flight training'
  ]

  ngOnInit() {
    this.subscriptions.push(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationStart)
      ).subscribe((event) => {
        const token = GlobalService.getToken();
        if (token && !this.jwtHelper.isTokenExpired(token)) {
          this.general.get();
          this.cisService.getUnreadNotifications();
        }
      }));

      const token = GlobalService.getToken();
      if (!token || this.jwtHelper.isTokenExpired(token)) {
        localStorage.removeItem(environment.tokenName);
        this.router.navigate(['/login']);
        return;
      }
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;

    if (!this.isMenuOpen) {
      this.contentMargin = 0;
    } else {
      this.contentMargin = 240;
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      if (!sub.closed) {
        sub.unsubscribe();
      }
    }
  }
  // sidenavEvents(str) {
  //   console.log(str);
  // }
}