import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FieldConfig} from '../../components/dynamic-form-master/models/field-config.interface';

export function prepareFormGroup(configs: FieldConfig[], tmpFormGroup?: UntypedFormGroup): UntypedFormGroup {
    tmpFormGroup = tmpFormGroup || new UntypedFormGroup({});
    for (const config of configs) {

        const startName = config?.startName;
        const endName = config?.endName;
        if (startName && endName) {
            const fromControl = new UntypedFormControl(config.value[startName]);
            tmpFormGroup.addControl(config.startName, fromControl);

            const toControl = new UntypedFormControl(config.value[endName]);
            tmpFormGroup.addControl(config.endName, toControl);
        } else {
            const tmpControl = new UntypedFormControl(config.value, config.validation);
            if(config.disabled) {
                tmpControl.disable();
            }
            tmpFormGroup.addControl(config.name, tmpControl)
        }
    }
    return tmpFormGroup;
}

export function setFormValue(tmpFormGroup: UntypedFormGroup, controlValues) {
    for(const controlName in  controlValues) {
        if(tmpFormGroup.get(controlName)) {
            tmpFormGroup.get(controlName).setValue(controlValues[controlName]);
        }
    }
}
